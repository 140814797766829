/* eslint-disable */
import { Grid } from '@mui/material';
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';

import {
  addNewCompany,
  changeCompany,
  deleteCompany,
  getCompany,
} from '../../../api/companies';
import { IconCloseMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import { formatCNPJ, formatCellphone, getErrorMessage } from '../../../helpers';
import {
  CloseButton,
  ContainerButtons,
  DialogContainer,
  FilledButton,
  InputBox,
  InputLabel,
  OutlinedButton,
  StyledDialog,
  TitleBox,
} from './styles';
import ConfirmationDialog from '../../ConfirmationDialog';

interface NewCompanyProps {
  open: boolean;
  closeModal: () => void;
  id?: number | undefined;
}

export function NewCompanyModal({
  open,
  closeModal,
  id,
}: NewCompanyProps): JSX.Element {
  const [name, setName] = useState('');
  const [responsible, setResponsible] = useState('');
  const [contact, setContact] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [cnpj, setCnpj] = useState('');

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const clearFields = (): void => {
    setName('');
    setResponsible('');
    setContact('');
    setAddress('');
    setNumber('');
    setComplement('');
    setCnpj('');
  }

  const createNewCompany = useCallback(async () => {
    try {
      if (name) {
        const response = await addNewCompany(
          name,
          responsible,
          contact,
          address,
          number,
          complement,
          cnpj
        );

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente');
        }

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        clearFields();
        setSnackbarMessage('Sucesso!');
        setOpenSnackbar(true);
        setErrorMessage(true);
        setTimeout(() => {
          closeModal();
        }, 1500);
      } else {
        throw new Error ('Campo obrigatório(s) em branco!')
      }
    } catch (err) {
      setSnackbarMessage(getErrorMessage(err));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, [
    name,
    cnpj,
    responsible,
    contact,
    address,
    number,
    complement,
    setSnackbarMessage,
    closeModal,
    setOpenSnackbar,
    setErrorMessage,
  ]);

  const handleChangeCompany = useCallback(async (): Promise<void> => {
    try {
      if (id) {
        const response = await changeCompany(
          id,
          name,
          responsible,
          contact,
          address,
          number,
          complement,
          cnpj
        );

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente');
        }

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        setSnackbarMessage('Empresa editada com sucesso!');
        setOpenSnackbar(true);
        setErrorMessage(false);

        clearFields();
        setTimeout(() => {
          closeModal();
        }, 2000);
      }
    } catch (err) {
      setSnackbarMessage(getErrorMessage(err));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, [
    id,
    name,
    cnpj,
    responsible,
    contact,
    address,
    number,
    complement,
    setSnackbarMessage,
    closeModal,
    setOpenSnackbar,
    setErrorMessage,
  ]);

  const handleDeleteCompany = async (): Promise<void> => {
    try {
      if (id) {
        const response = await deleteCompany(id);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente');
        }

        setSnackbarMessage('Usuário excluído com sucesso');
        setOpenSnackbar(true);
        setErrorMessage(false);

        setTimeout(() => {
          getDataCallback();
          clearFields();
          closeModal();
        }, 2000);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  const getDataCallback = useCallback(async () => {
    try {
      if (id) {
        const response = await getCompany(id);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente');
        }

        setName(response.data?.name || '');
        setResponsible(response.data?.responsible || '');
        setContact(response.data?.contact || '');
        setAddress(response.data?.address || '');
        setNumber(response.data?.number || '');
        setComplement(response.data?.complement || '');
        setCnpj(response.data?.cnpj || '');
      }
    } catch (err) {
      setSnackbarMessage(getErrorMessage(err));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, [id, setErrorMessage, setOpenSnackbar, setSnackbarMessage]);

  const handleCloseModal = (): void => {
    clearFields();
    closeModal();
  }

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  return (
    <StyledDialog open={open} onClose={handleCloseModal}>
      <DialogContainer>
        <CloseButton onClick={handleCloseModal}>{IconCloseMS}</CloseButton>
        {id ? (
          <TitleBox>Editar dados</TitleBox>
        ) : (
          <TitleBox>Nova empresa</TitleBox>
        )}

        <Grid container spacing={6}>
          <Grid item xs={6}>
            <InputLabel variant="subtitle2">Nome da Empresa *</InputLabel>
            <InputBox
              sx={{ width: 500 }}
              placeholder="Preencha aqui o nome da empresa"
              value={name}
              required
              error={!name}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel variant="subtitle2">CNPJ</InputLabel>
            <InputBox
              sx={{ width: 500 }}
              placeholder="Preencha aqui o número do CNPJ"
              value={cnpj}
              inputProps={{ maxLength: 14 }}
              onChange={(e) => setCnpj(formatCNPJ(e.target.value))}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel variant="subtitle2">Responsável</InputLabel>
            <InputBox
              sx={{ width: 500 }}
              placeholder="Preencha aqui o nome do responsável"
              value={responsible}
              onChange={(e) => setResponsible(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel variant="subtitle2">Contato</InputLabel>
            <InputBox
              sx={{ width: 500 }}
              placeholder="Preencha o nº do telefone com código de área"
              value={contact}
              inputProps={{ maxLength: 14 }}
              onChange={(e) => setContact(formatCellphone(e.target.value))}
            />
          </Grid>
          <Grid item xs={7}>
            <InputLabel variant="subtitle2">Endereço</InputLabel>
            <InputBox
              sx={{ width: 650 }}
              placeholder="Preencha aqui o endereço"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <InputLabel variant="subtitle2">Número</InputLabel>
            <InputBox
              placeholder="nº"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <InputLabel variant="subtitle2">Complemento</InputLabel>
            <InputBox
              placeholder=""
              value={complement}
              onChange={(e) => setComplement(e.target.value)}
            />
          </Grid>
        </Grid>
        <ContainerButtons>
          {id && (
            <ConfirmationDialog
              title="excluir empresa"
              text={`a empresa ${name}`}
              modalCallback={handleDeleteCompany}
            />
          )}
          <OutlinedButton onClick={handleCloseModal}>Cancelar</OutlinedButton>
          {id ? (
            // eslint-disable-next-line no-console
            <FilledButton onClick={handleChangeCompany}>Salvar</FilledButton>
          ) : (
            <FilledButton onClick={createNewCompany}>Salvar</FilledButton>
          )}
        </ContainerButtons>
      </DialogContainer>
    </StyledDialog>
  );
}
