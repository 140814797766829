/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getDashboard } from '../../../api/constructions';
import { DashboardData } from '../../../api/constructions/types';
import { Constants } from '../../../constants/reports';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage, reportsPercentage } from '../../../helpers';
import {
  ChartPaper,
  ChartTitle,
  ChartValue,
  InnerBox,
  ChartBox,
  PercentChartLabel,
  DashboardPaper,
  DashboardBox,
  TotalReports,
  TotalReportsValue,
  ReportsTypography,
  ReportsValue,
  ChartsContainer,
  BackProgress,
  FrontProgress,
} from './styles';

interface DashboardProps {
  chart?: boolean;
  dashboard?: boolean;
}

export function Dashboard({ chart, dashboard }: DashboardProps): JSX.Element {
  const {
    setOpenSnackbar,
    setErrorMessage,
    setSnackbarMessage,
    dashLabel,
    setDashLabel,
  } = useContext(GlobalContext);
  const [dashboardData, setDashboardData] = useState<DashboardData>();

  const { id } = useParams();
  const constructionId = parseInt(id as string, 10);

  const totalReports = dashboardData ? dashboardData.total : 0;
  const reportsList = [
    {
      label: 'executadas',
      value: dashboardData ? dashboardData.grouped.concluida : 0,
    },
    {
      label: 'carta enviada',
      value: dashboardData ? dashboardData.grouped.carta_enviada : 0,
    },
    {
      label: 'vistoria agendada',
      value: dashboardData ? dashboardData.grouped.vistoria_agendada : 0,
    },
    {
      label: 'remarcada',
      value: dashboardData ? dashboardData.grouped.remarcada : 0,
    },
    {
      label: 'produzindo relatório',
      value: dashboardData ? dashboardData.grouped.produzindo_relatorio : 0,
    },
    {
      label: 'cancelada / rejeitada',
      value: dashboardData ? dashboardData.grouped.cancelada_rejeitada : 0,
    },
  ];

  const fullReportList = [
    {
      status: '6',
      label: 'executadas',
      value: dashboardData?.concluida || 0,
    },
    {
      status: '1',
      label: 'carta enviada',
      value: dashboardData?.carta_enviada || 0,
    },
    {
      status: '2',
      label: 'vistoria agendada',
      value: dashboardData?.vistoria_agendada || 0,
    },
    {
      status: '3',
      label: 'remarcada pelo ocupante',
      value: dashboardData?.remarcada_pelo_ocupante || 0,
    },
    {
      status: '4',
      label: 'remarcada pela real price',
      value: dashboardData?.remarcada_pela_real_price || 0,
    },
    {
      status: '5',
      label: 'produzindo relatório',
      value: dashboardData?.produzindo_relatorio || 0,
    },
    {
      status: '9',
      label: 'cancelada pelo cliente',
      value: dashboardData?.cancelada_pelo_cliente || 0,
    },
    {
      status: '7',
      label: 'contato sem sucesso',
      value: dashboardData?.contato_sem_sucesso || 0,
    },
    {
      status: '8',
      label: 'não aprovada pelo ocupante',
      value: dashboardData?.nao_aprovada_pelo_cliente || 0,
    },
    {
      status: '10',
      label: 'rejeitada pelo ocupante',
      value: dashboardData?.rejeitada_pelo_ocupante || 0,
    },
    {
      status: '11',
      label: 'notificação via cartório',
      value: dashboardData?.notificacao_via_cartorio || 0,
    },
  ];

  const [activeIndicies, setActiveIndicies] = useState<boolean[]>(() =>
    fullReportList?.map(() => false)
  );

  const handleChangeLabel = (status: string, index: number): void => {
    dashLabel.indexOf(status) === -1
      ? setDashLabel((prev: string[]) => [...prev, status])
      : setDashLabel(dashLabel.filter((item) => item !== status));
    setActiveIndicies(
      activeIndicies.map((bool: boolean, j: number) =>
        j === index ? !bool : bool
      )
    );
  };

  const getDataCallback = useCallback(async () => {
    try {
      const response = await getDashboard(constructionId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      setDashboardData(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  return (
    <>
      {chart && (
        <Grid item xs={8}>
          <ChartTitle>{Constants.chartTitle}</ChartTitle>
          <ChartPaper>
            <ChartsContainer>
              {reportsList.map((data) => (
                <ChartBox key={data.label}>
                  <InnerBox>
                    <BackProgress
                      variant="determinate"
                      value={100}
                      size={100}
                      thickness={7}
                    />
                    <FrontProgress
                      variant="determinate"
                      value={reportsPercentage(data.value, totalReports)}
                      size={100}
                      thickness={7}
                    />
                    <ChartValue>
                      {reportsPercentage(data.value, totalReports) > 10
                        ? reportsPercentage(data.value, totalReports)
                        : `0${reportsPercentage(data.value, totalReports)}`}
                      %
                    </ChartValue>
                  </InnerBox>
                  <PercentChartLabel>{data.label}</PercentChartLabel>
                </ChartBox>
              ))}
            </ChartsContainer>
          </ChartPaper>
        </Grid>
      )}
      {dashboard && (
        <Grid item xs={4}>
          <ChartTitle>{Constants.dashboard}</ChartTitle>
          <DashboardPaper>
            <DashboardBox>
              <TotalReports>{Constants.totalReports}</TotalReports>
              <TotalReportsValue>{totalReports}</TotalReportsValue>
            </DashboardBox>
            {fullReportList.map((data, index) => (
              <DashboardBox
                key={data.label}
                onClick={() => handleChangeLabel(data.status, index)}
                className={activeIndicies[index] ? 'active' : ''}
              >
                <ReportsTypography>{data.label}</ReportsTypography>
                <ReportsValue>{data.value}</ReportsValue>
              </DashboardBox>
            ))}
          </DashboardPaper>
        </Grid>
      )}
    </>
  );
}
