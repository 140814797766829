import {
  Box,
  ButtonBase,
  Dialog,
  DialogTitle,
  tooltipClasses,
  TooltipProps,
  Tooltip,
  Typography,
  TextareaAutosize,
  IconButton,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const DialogButton = styled(ButtonBase)(() => ({
  padding: '0 6px',
  width: '100%',
  display: 'flex',
  gap: '8px',
  color: '#FFFFFF',
  '&:hover': {
    color: '#E89710',
  },
  '&:active': {
    color: '#E89710',
  },
  '&.disabled': {
    '& span': {
      color: '#585c67',
      cursor: 'default',
    },
  },
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    color: '#8B8C8E',
    fontSize: 12,
    fontWeight: '400',
    padding: '2px 4px',
    textTransform: 'lowercase',
    marginTop: '-2px !important',
    '@media (min-width: 1980px)': {
      display: 'none',
    },
  },
}));

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    backgroundColor: '#313234',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: '600px',
  minHeight: '400px',
  padding: '40px',
}));

export const CloseButton = styled(ButtonBase)(() => ({
  color: '#707070',
  float: 'right',
  '&:hover': {
    color: '#EDEFF2',
  },
  '&:active': {
    color: '#E89710',
  },
  '& span': { fontSize: '28px', fontWeight: '600' },
}));

export const StyledTitle = styled(DialogTitle)(() => ({
  color: '#EDEFF2',
  fontWeight: '700',
  padding: '16px 0',
  fontSize: '21px',
}));

export const RefNumber = styled(Typography)(() => ({
  color: '#E67715',
  fontWeight: '700',
  padding: '10px 16px',
  fontSize: '18px',
}));

export const TextTypography = styled(Typography)(() => ({
  color: '#EDEFF2',
  fontWeight: '500',
  fontSize: '18px',
  padding: '16px',
}));

export const TextArea = styled(TextareaAutosize)(() => ({
  background: 'transparent',
  fontFamily: 'Montserrat',
  fontSize: 16,
  color: '#FFFFFF',
  width: '100%',
  minHeight: 42,
  borderRadius: 8,
  padding: 8,
}));

export const ContainerActionButtons = styled('div')(() => ({
  display: 'flex',
  marginTop: 49,
  width: '100%',
  justifyContent: 'space-between',
}));

export const EditButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginLeft: 20,
}));

export const CancelButton = styled(ButtonBase)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  color: theme.palette.secondary.main,
  width: 200,
  height: 48,
  borderRadius: 6,
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.fontSize,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
  },
}));

export const SaveButton = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: '#FFFFFF',
  width: 200,
  height: 48,
  borderRadius: 6,
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.fontSize,
  '&:hover': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.8),
  },
}));
