import { BaseUrl, myHeadersWithTokenContentType } from '../global';
import {
  DetailResponse,
  LoginProps,
  NewPassword,
  ResetPasswordProps,
  TokenProps,
} from './types';

export const login = (email: string, password: string): Promise<LoginProps> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  const urlencoded = new URLSearchParams();
  urlencoded.append('username', email);
  urlencoded.append('password', password);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
  };

  return fetch(`${BaseUrl}/api/V1/login`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result);
    })
    .catch((error) => new Error(error));
};

export const resetPassword = (
  password: NewPassword
): Promise<ResetPasswordProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(password),
  };

  return fetch(`${BaseUrl}/api/V1/update-password`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result);
    })
    .catch((error) => new Error(error));
};

export const firstAccessEmail = (email: string): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/reset-password/${email}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const firstAccessToken = (
  email: string,
  token: string
): Promise<TokenProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/reset-password/${email}/${token}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const resetPasswordWithToken = (
  token: string,
  password: FormData
): Promise<DetailResponse> => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: password,
  };

  return fetch(`${BaseUrl}/api/V1/reset-password`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
