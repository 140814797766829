/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getAllCompanies } from '../../api/companies';
import { CompanyProps } from '../../api/companies/types';
import { getAllConstructions } from '../../api/constructions';
import { ConstructionData } from '../../api/constructions/types';
import { BaseUrl, myHeadersWithTokenContentType } from '../../api/global';
import construction from '../../assets/images/construction.png';
import NewConstruction from '../../components/Dialog/NewConstruction';
import Menu from '../../components/Menu';
import Snackbar from '../../components/Snackbar';
import { Constants } from '../../constants/home';
import { IconHomeMS, IconSearchMS } from '../../constants/icons';
import { GlobalContext } from '../../context/global';
import { getErrorMessage } from '../../helpers';
import { useStoragedJwt } from '../../hooks/useDecodedJwt';
import {
  ButtonContained,
  CardGrid,
  CardTitle,
  ConstructionImg,
  FilterContainer,
  SearchContainer,
  StyledCard,
  StyledGrid,
  SubtitleTypography,
  TitleBox,
  TitleTypography,
  DatePicker,
  NewConstructionButton,
} from './styles';

export function Home(): JSX.Element {
  const [constructionData, setConstructionData] = useState<ConstructionData[]>(
    []
  );
  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const decoded = useStoragedJwt();
  const companyId = decoded?.user.company_id as number;
  const constrPerPage = 12;

  const [companies, setCompanies] = useState<CompanyProps[]>([]);
  const [companyIdFilter, setCompanyIdFilter] = useState(0);

  const [searchString, setSearchString] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState(1);

  const [openNewConstructionModal, setOpenNewConstructionModal] =
    useState(false);

  const companyIdBySessionStorage = sessionStorage.getItem('company');

  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const handleReload = (): void => setReload(true);

  let URL = `${BaseUrl}/api/V1/constructions/search?`;

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      return;
    }

    try {
      const response = await getAllConstructions(
        companyId,
        page,
        constrPerPage
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      if (response.data) {
        setConstructionData([...constructionData, ...response.data]);
        setLastCalledPage(page);
      }

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, [page]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getCompanies() {
    const response = await getAllCompanies(1, 50, '');
    if (response.data) {
      setCompanies(response.data);
    }
  }

  const compositeFiltered = useCallback(async () => {
    URL += `search_string=${searchString}`;

    if (startDate) {
      URL += `&start_date=${startDate}`;
    }
    if (endDate) {
      URL += `&end_date=${endDate}`;
    }
    if (status !== 0) {
      URL += `&status=${status}`;
    }
    if (companyIdFilter !== 0) {
      URL += `&company_id=${companyIdFilter}`;
    }
    if (companyIdFilter === 0 && decoded?.user.role !== 2) {
      URL += `&company_id=${companyId}`;
    }

    if (
      startDate === '' &&
      endDate === '' &&
      searchString === '' &&
      status === 0 &&
      companyIdFilter === 0
    ) {
      getDataCallback();
    } else {
      const requestOptions = {
        method: 'GET',
        headers: myHeadersWithTokenContentType(),
      };
      const response = await fetch(`${URL}&page=1&size=50`, requestOptions)
        .then((response) => response.text())
        .then((result) => JSON.parse(result))
        .catch((error) => new Error(error));

      if (response.data) {
        setConstructionData(response.data);
      }
    }
  }, [
    searchString,
    status,
    startDate,
    endDate,
    companyIdFilter,
    getDataCallback,
  ]);

  const getConstructionsById = useCallback(async () => {
    if (companyIdFilter !== 0 || reload) {
      try {
        const requestOptions = {
          method: 'GET',
          headers: myHeadersWithTokenContentType(),
        };
        const response = await fetch(
          `${URL}company_id=${companyIdFilter}&status=1&size=30`,
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => JSON.parse(result))
          .catch((error) => new Error(error));

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente!');
        }

        if (response.data) {
          setConstructionData(response.data);
          setReload(false);
        }
        setLoading(false);
      } catch (err) {
        setSnackbarMessage(getErrorMessage(err));
        setErrorMessage(true);
        setOpenSnackbar(true);
        setLoading(false);
      }
    }
  }, [companyIdFilter, reload]);

  const handleChangeStatus = (e: SelectChangeEvent<unknown>): void => {
    setStatus(Number(e.target.value));
  };

  const handleChangeSelect = (e: SelectChangeEvent<number>): void => {
    setCompanyIdFilter(Number(e.target.value));
    sessionStorage.setItem('company', String(e.target.value));
  };

  useEffect(() => {
    if (companyIdBySessionStorage !== '0') {
      setCompanyIdFilter(Number(companyIdBySessionStorage));
      getConstructionsById();
    } else {
      getDataCallback();
    }
    if (decoded?.user.role !== 2) {
      getCompanies();
    }
  }, [
    getDataCallback,
    page,
    companyIdFilter,
    companyIdBySessionStorage,
    reload,
  ]);

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <>
      <Menu />
      <StyledGrid>
        <TitleTypography>
          {IconHomeMS}
          {Constants.start}
        </TitleTypography>

        {decoded?.user.role !== 2 && (
          <Select
            style={{
              background: '#fafafa',
              width: 250,
              height: 48,
              borderRadius: 8,
              marginTop: 16,
              marginBottom: 16,
            }}
            defaultValue={0}
            value={companyIdFilter}
            disabled={!!loading}
            onChange={handleChangeSelect}
          >
            <MenuItem disabled value={0}>
              Selecione a empresa
            </MenuItem>
            {companies &&
              companies.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
          </Select>
        )}

        <SearchContainer>
          <FilterContainer>
            <input
              type="search"
              placeholder="Buscar obras"
              onChange={(e) => setSearchString(e.target.value)}
            />
            <DatePicker>
              <p>Selecione o período</p>
              <input
                id="date-picker"
                type="date"
                onChange={(e) => setStartDate(e.target.value)}
              />
              <input type="date" onChange={(e) => setEndDate(e.target.value)} />
            </DatePicker>
            <Select
              variant="filled"
              value={status}
              onChange={(e) => handleChangeStatus(e)}
              style={{
                background: '#fafafa',
                width: 230,
                height: 48,
                borderRadius: 8,
                paddingBottom: 16,
              }}
            >
              <MenuItem value={1}>Obras em andamento</MenuItem>
              <MenuItem value={2}>Obras finalizadas</MenuItem>
              <MenuItem value={0}>Todas as obras</MenuItem>
            </Select>
          </FilterContainer>
          <ButtonContained onClick={compositeFiltered}>
            {IconSearchMS}&nbsp;&nbsp;pesquisar
          </ButtonContained>
        </SearchContainer>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 50,
          }}
        >
          <SubtitleTypography>{Constants.constructionList}</SubtitleTypography>
          {decoded?.user.role !== 2 && (
            <NewConstructionButton
              onClick={() =>
                setOpenNewConstructionModal(!openNewConstructionModal)
              }
            >
              nova obra
            </NewConstructionButton>
          )}
        </div>
        <CardGrid>
          {constructionData?.map(
            (data) =>
              data.is_active !== false && (
                <StyledCard key={data.id}>
                  <Link
                    to={`/reports/${data?.id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <ConstructionImg
                      image={data.picture ? data.picture : construction}
                      title={data.name}
                    />
                  </Link>
                  <TitleBox>
                    <CardTitle>{data.name}</CardTitle>
                  </TitleBox>
                </StyledCard>
              )
          )}
        </CardGrid>
        {!constructionData.length && <h1>Nenhuma obra encontrada</h1>}
      </StyledGrid>
      {openSnackbar && <Snackbar />}
      <NewConstruction
        open={openNewConstructionModal}
        onClose={() => setOpenNewConstructionModal(!openNewConstructionModal)}
        id={companyIdFilter}
        handleReload={handleReload}
      />
    </>
  );
}
