import { Box, Button, Dialog, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '30px',
    backgroundColor: '#313234',
    minHeight: '600px',
    padding: '60px 80px',
    minWidth: '760px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '36px',
  fontWeight: '600',
  color: theme.palette.secondary.dark,
}));

export const SectionSubtitle = styled(Typography)(() => ({
  fontSize: '16px',
  margin: '60px 0',
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '480px',
  margin: '20px 0',

  '& .MuiInputBase-input': {
    color: '#FFFFFF',
  },
}));

export const ButtonBox = styled(Box)(() => ({
  margin: '80px 0 40px',
  display: 'flex',
  gap: '40px',
  justifyContent: 'center',
}));

export const OutlinedButton = styled(Button)(({ theme }) => ({
  maxWidth: '220px',
  border: `1.5px solid ${theme.palette.secondary.main}`,
  color: theme.palette.secondary.main,
  fontWeight: '600',
  backgroundColor: 'transparent',
  textTransform: 'uppercase',
  borderRadius: '16px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
    filter: 'brightness(85%)',
  },
}));

export const FilledButton = styled(Button)(({ theme }) => ({
  maxWidth: '220px',
  backgroundColor: theme.palette.secondary.main,
  color: '#FFFFFF',
  textTransform: 'uppercase',
  borderRadius: '16px',

  '&:hover': {
    filter: 'brightness(85%)',
  },
}));
