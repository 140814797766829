/* eslint-disable */
import Box from '@mui/material/Box';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import logo from '../../assets/images/logo-realprice-sm.svg';
import {
  IconAppartment,
  IconArrowBackMS,
  IconArrowForwardMS,
  IconHomeMS,
  IconLogoutMS,
  IconPersonMS,
  IconPieChartMS,
  IconSettingsMS,
} from '../../constants/icons';
import { useStoragedJwt } from '../../hooks/useDecodedJwt';
import { Navbar } from '../Navbar';
import {
  active,
  ArrowBox,
  BackArrowBox,
  BoxContainer,
  Drawer,
  DrawerHeader,
  inactive,
  LogoutItem,
  MenuButton,
  MenuIcon,
  MenuList,
  MenuListItem,
  StyledLogo,
  StyledTooltip,
} from './styles';

export default function Menu(): JSX.Element {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const decoded = useStoragedJwt();

  const menuList = [
    { name: 'início', icon: IconHomeMS, url: '/home' },
    { name: 'configurações', icon: IconSettingsMS, url: '/settings' },
  ];

  const menuList2 = [
    { name: 'início', icon: IconHomeMS, url: '/home' },
    { name: 'empresas', icon: IconAppartment, url: '/companies' },
    { name: 'usuários', icon: IconPersonMS, url: '/users' },
    { name: 'configurações', icon: IconSettingsMS, url: '/settings' },
  ];

  const handleDrawerOpen = (): void => {
    setOpen(!open);
  };

  const handleLogout = (): void => {
    localStorage.removeItem('precautionary-token');
    navigate('/login');
    location.reload();
  };

  return (
    <>
      <Navbar open={open} />
      <BoxContainer>
        <Drawer variant="permanent" open={open}>
          <Box>
            <DrawerHeader>
              <StyledLogo image={logo} title="logo" />
            </DrawerHeader>
            <MenuList>
              <Box>
                {decoded?.user.role === 2
                  ? menuList.map((data) => (
                    <MenuListItem key={data.name} disablePadding>
                      <NavLink
                        to={data.url}
                        style={({ isActive }) =>
                          isActive ? active : inactive
                        }
                      >
                        {!open ? (
                          <StyledTooltip title={data.name} placement="bottom">
                            <MenuButton open={open} disableTouchRipple>
                              <MenuIcon open={open}>
                                {data.icon}
                                {open && data.name}
                              </MenuIcon>
                            </MenuButton>
                          </StyledTooltip>
                        ) : (
                          <MenuButton open={open} disableTouchRipple>
                            <MenuIcon open={open}>
                              {data.icon}
                              {open && data.name}
                            </MenuIcon>
                          </MenuButton>
                        )}
                      </NavLink>
                    </MenuListItem>
                  ))
                  : menuList2.map((data) => (
                    <MenuListItem key={data.name} disablePadding>
                      <NavLink
                        to={data.url}
                        style={({ isActive }) =>
                          isActive ? active : inactive
                        }
                      >
                        {!open ? (
                          <StyledTooltip title={data.name} placement="bottom">
                            <MenuButton open={open} disableTouchRipple>
                              <MenuIcon open={open}>
                                {data.icon}
                                {open && data.name}
                              </MenuIcon>
                            </MenuButton>
                          </StyledTooltip>
                        ) : (
                          <MenuButton open={open} disableTouchRipple>
                            <MenuIcon open={open}>
                              {data.icon}
                              {open && data.name}
                            </MenuIcon>
                          </MenuButton>
                        )}
                      </NavLink>
                    </MenuListItem>
                  ))}
              </Box>
              <LogoutItem disablePadding>
                {!open ? (
                  <StyledTooltip title="sair" placement="bottom">
                    <MenuButton
                      open={open}
                      disableTouchRipple
                      onClick={handleLogout}
                    >
                      <MenuIcon open={open}>
                        {IconLogoutMS}
                        {open && 'sair'}
                      </MenuIcon>
                    </MenuButton>
                  </StyledTooltip>
                ) : (
                  <MenuButton
                    open={open}
                    disableTouchRipple
                    onClick={handleLogout}
                  >
                    <MenuIcon open={open}>
                      {IconLogoutMS}
                      {open && 'sair'}
                    </MenuIcon>
                  </MenuButton>
                )}
              </LogoutItem>
            </MenuList>
          </Box>
          <BackArrowBox open={open} />
          <ArrowBox open={open} onClick={handleDrawerOpen}>
            {open ? IconArrowBackMS : IconArrowForwardMS}
          </ArrowBox>
        </Drawer>
      </BoxContainer>
    </>
  );
}
