/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
import {
  Box,
  Card,
  CardMedia,
  Grid,
  Typography,
  ButtonBase,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const StyledGrid = styled(Grid)(() => ({
  padding: '40px 120px 60px 228px',
}));

export const TitleTypography = styled(Typography)(() => ({
  color: '#EDEFF2',
  fontSize: '21px',
  fontWeight: '600',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  '& span': {
    color: '#E89710',
    fontSize: '24px',
    paddingBottom: '3px',
  },
}));

export const SubtitleTypography = styled(Typography)(() => ({
  color: '#EDEFF2',
  fontSize: '18px',
  fontWeight: '600',
  // marginTop: '50px',
}));

export const CardGrid = styled(Grid)(() => ({
  margin: '32px 0',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '32px',
}));

export const StyledCard = styled(Card)(() => ({
  minWidth: '352px',
  backgroundColor: '#FAFCFF',
  borderRadius: '10px',
  padding: '16px',
}));

export const ConstructionImg = styled(CardMedia)(() => ({
  width: '320px',
  height: '250px',
  borderRadius: '10px',
}));

export const TitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  marginTop: '16px',
}));

export const CardTitle = styled(Typography)(() => ({
  fontSize: '21px',
  fontWeight: '600',
  color: '#313234',
}));

export const SearchContainer = styled(Box)(() => ({
  height: 64,
  maxWidth: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 100,
}));

export const FilterContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 24,

  '& input[type=search]': {
    background: 'transparent',
    outline: 'none',
    width: 348,
    height: 48,
    borderRadius: 30,
    border: '2px solid #FAFCFF',
    font: '400 0.95rem Montserrat',
    padding: '0 16px',
    color: '#FAFCFF',
  },

  '& select': {
    width: 256,
    height: 48,
    borderRadius: 6,
    border: '1px solid #2B3550',
    font: '400 0.95rem Montserrat',
    padding: '0 8px',
  },
}));

export const DateContainer = styled(Box)(() => ({
  '& .ant-picker-input': {
    font: '400 0.95rem Montserrat',
  },
}));

export const ButtonContained = styled(ButtonBase)(() => ({
  width: 'auto',
  height: 48,
  background: '#E67715',
  color: '#FFFFFF',
  borderRadius: 30,
  font: '700 0.95rem Montserrat',
  padding: 8,
}));

export const DatePicker = styled(Box)(() => ({
  background: 'whitesmoke',
  borderTopLeftRadius: 8,
  borderBottomLeftRadius: 8,
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  width: 300,
  display: 'flex',
  alignItems: 'center',

  '& p': {
    display: 'none',
  },

  '& input[type=date]': {
    height: 48,
    border: 'none',
    outline: 'none',
    borderRadius: 16,
    background: 'transparent',
    padding: 8,
    fontFamily: 'Montserrat',
    fontSize: 14,
    '&::-webkit-calendar-picker-indicator': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="%23E67715" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>')`,
    },
  },
}));

export const NewConstructionButton = styled(ButtonBase)(() => ({
  backgroundColor: '#E89710',
  color: '#FFFFFF',
  font: '700 16px Montserrat',
  padding: 16,
  width: 200,
  borderRadius: 8,

  '&:hover': {
    backgroundColor: alpha('#E89710', 0.8),
  },
}));
