import { TextField } from '@mui/material';
import { FormEvent, useContext, useState } from 'react';
import { PatternFormat } from 'react-number-format';

import { firstAccessEmail, firstAccessToken } from '../../api/login';
import logo from '../../assets/images/logo-realprice.png';
import { EmailIcon } from '../../components/InputIcon';
import Snackbar from '../../components/Snackbar';
import { Constants } from '../../constants/login';
import { GlobalContext } from '../../context/global';
import { getErrorMessage, validateEmail } from '../../helpers';
import { ChangePassword } from './ChangePassword';
import {
  ContainerGrid,
  InputBox,
  InputTitle,
  LoginGrid,
  LogoGrid,
  LogoImg,
  PatternFormatStyles,
  StyledTextField,
  SubmitButton,
} from './styles';

export function FirstAccess(): JSX.Element {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  const [emailToken, setEmailToken] = useState('');
  const [tempToken, setTempToken] = useState('');

  const [openModal, setOpenModal] = useState(false);

  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleRefreshToken = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    const trimEmail = email.trim();
    const trimToken = emailToken.replace(/\s/g, '');

    try {
      const response = await firstAccessToken(trimEmail, trimToken);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente!');
      }

      if (response.data) {
        setTempToken(response.data?.token);
        setOpenModal(true);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleSendEmail = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    const trimEmail = email.trim();

    try {
      const response = await firstAccessEmail(trimEmail);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      setSnackbarMessage('Um código foi enviado para o seu email');
      setOpenSnackbar(true);
      setErrorMessage(false);
      setHasToken(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  return (
    <ContainerGrid container>
      <LogoGrid item xs={8}>
        <LogoImg image={logo} title="logo" />
      </LogoGrid>
      <LoginGrid item xs={4}>
        {hasToken ? (
          <InputBox component="form" id="token" onSubmit={handleRefreshToken}>
            <InputTitle>Primeiro Acesso</InputTitle>
            <PatternFormat
              format="### ###"
              mask="&#8212;"
              customInput={TextField}
              variant="standard"
              id="token-input"
              helperText="insira o código de 6 dígitos"
              color="secondary"
              value={emailToken}
              onChange={(e) => setEmailToken(e.target.value)}
              sx={PatternFormatStyles}
            />
            <SubmitButton form="token" type="submit" disabled={emailError}>
              ENVIAR
            </SubmitButton>
          </InputBox>
        ) : (
          <InputBox
            component="form"
            id="first-access"
            onSubmit={handleSendEmail}
          >
            <InputTitle>Primeiro Acesso</InputTitle>
            <StyledTextField
              required
              id="email"
              variant="standard"
              InputProps={EmailIcon}
              placeholder={Constants.emailPlaceholder}
              error={emailError}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                !validateEmail(e.target.value)
                  ? setEmailError(true)
                  : setEmailError(false);
              }}
            />
            <SubmitButton
              form="first-access"
              type="submit"
              disabled={emailError}
            >
              ENVIAR
            </SubmitButton>
          </InputBox>
        )}
      </LoginGrid>
      <ChangePassword
        open={openModal}
        closeModal={() => setOpenModal(!openModal)}
        tempToken={tempToken}
      />
      {openSnackbar && <Snackbar />}
    </ContainerGrid>
  );
}
