/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
import { useContext, useEffect, useState } from 'react';

import { getART } from '../../../api/constructions';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import { Iframe } from './styles';

interface PdfViewerProps {
  id: number;
}
export function PdfViewer({ id }: PdfViewerProps): JSX.Element {
  const [pdfURL, setPdfURL] = useState('');

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const getARTPDF = async () => {
    try {
      const response = await getART(id);
      const binaryData = new Blob([response], { type: 'application/pdf' });
      const url = URL.createObjectURL(binaryData);
      setPdfURL(url);
      return () => URL.revokeObjectURL(url);
      // setPdfFile(file);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    getARTPDF();
  }, []);

  return <Iframe src={pdfURL} width="50%" height="400" />;
}
