/* eslint-disable react-hooks/exhaustive-deps */
import { CardMedia } from '@mui/material';
import { ChangeEvent, useContext, useState } from 'react';

import { changeLogo } from '../../../api/companies';
import { IconCloseMS } from '../../../constants/icons';
import { Constants } from '../../../constants/settings';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage, validateImg } from '../../../helpers';
import {
  CloseButton,
  DialogButton,
  DialogContainer,
  LogoBox,
  LogoTypography,
  SaveButton,
  StyledDialog,
  StyledTitle,
  UploadBox,
  UploadButton,
} from './styles';

interface DownloadDialogProps {
  id: number;
  lastLogo: string;
  modalCallback: () => void;
}

export default function LogoDialog({
  id,
  lastLogo,
  modalCallback,
}: DownloadDialogProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [newLogo, setNewLogo] = useState('');
  const [logoFile, setLogoFile] = useState<FormData>();
  const {
    setOpenSnackbar,
    setErrorMessage,
    setSnackbarMessage,
    setNavbarLogo,
  } = useContext(GlobalContext);

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateImg(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const file = e.target.files[0];
    setNewLogo(URL.createObjectURL(file));

    const formData = new FormData();
    formData.append('logo', file);
    setLogoFile(formData);
  };

  const handleSubmit = async (): Promise<void> => {
    if (!logoFile) {
      handleClose();
      return;
    }

    try {
      const response = await changeLogo(id, logoFile);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      setSnackbarMessage('Upload realizado com sucesso!');
      setOpenSnackbar(true);
      setErrorMessage(false);
      setTimeout(() => {
        setNavbarLogo(newLogo);
        handleClose();
        modalCallback();
      }, 2000);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
      handleClose();
    }
  };

  return (
    <>
      <DialogButton disableTouchRipple onClick={handleOpen}>
        {Constants.uploadLogo}
      </DialogButton>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="upload logo"
      >
        <DialogContainer>
          <CloseButton disableTouchRipple onClick={handleClose}>
            {IconCloseMS}
          </CloseButton>

          <StyledTitle>{Constants.uploadLogo}</StyledTitle>
          <LogoBox>
            {newLogo ? (
              <CardMedia
                image={newLogo}
                title="logo"
                component="img"
                height="60"
                sx={{ objectFit: 'contain' }}
              />
            ) : (
              !newLogo &&
              lastLogo && (
                <CardMedia
                  image={lastLogo}
                  title="logo"
                  component="img"
                  height="60"
                  sx={{ objectFit: 'contain' }}
                />
              )
            )}
          </LogoBox>
          <LogoTypography>{Constants.uploadText}</LogoTypography>
          <UploadBox>
            <label htmlFor="uploadLogo">
              <input
                accept="image/*"
                id="uploadLogo"
                type="file"
                style={{ display: 'none' }}
                onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                  handleFileUpload(e)
                }
              />
              <UploadButton variant="contained" component="span">
                {Constants.selectLogo}
              </UploadButton>
            </label>
            <SaveButton onClick={handleSubmit}>{Constants.save}</SaveButton>
          </UploadBox>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
