export const checkColor = (status: number): string => {
  if (status > 6 && status <= 12) {
    return '#F03131';
  }
  return '#F2F2F2';
};

export const checkStatus = (status: number): string => {
  if (status > 0 && status < 6) {
    return '#378EF2';
  }
  if (status === 6) {
    return '#8BC86F';
  }
  if (status > 6 && status <= 12) {
    return '#F03131';
  }
  return '#F2F2F2';
};

export const convertDate = (date: string): string => {
  const formatDate = new Date(date);
  const localDate = formatDate.toLocaleDateString();
  return `${localDate}`;
};

export const convertTime = (date: string): string => {
  const time = date.substring(11, 16);
  return `${time}`;
};

export const formatCellphone = (value: string): string => {
  const newValue = value.replace(/[^0-9]+/g, '');
  if (newValue.length === 11) {
    return newValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
  }
  return newValue.replace(/(\d{2})(\d{4})(\d)/, '($1)$2-$3');
};

export const formatCNPJ = (value: string): string => {
  const newValue = value.replace(/[^0-9]+/g, '');
  return newValue.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5'
  );
};

export const formatConclusion = (date: string): string => {
  return date.split('-').reverse().join('/');
};

export const getErrorMessage = (error: unknown): string => {
  if (error instanceof Error) {
    if (error.name === 'TypeError') {
      return 'Algo deu errado, tente novamente';
    }
    return error.message;
  }
  return 'Algo deu errado, tente novamente';
};

export const getExpiration = (): number => {
  const now = new Date();
  const expirationTime = 43200000; // 12 horas
  const result = now.getTime() + expirationTime;
  return result;
};

export const reportsPercentage = (
  reportsGroup: number,
  reportsTotal: number
): number => {
  let percentage = 0;
  if (reportsGroup !== 0 && reportsTotal !== 0) {
    percentage = Number(((100 * reportsGroup) / reportsTotal).toFixed(2));
  }
  return percentage;
};

export const reverseConclusion = (date: string): string => {
  return date.split('/').reverse().join('-');
};

export const validateEmail = (email: string): boolean => {
  return /\S+@\S+\.\S+/.test(email);
};

export const validateImg = (e: string): boolean => {
  return /^image/.test(e);
};

export const validatePDF = (e: string): boolean => {
  return /^pdf/.test(e);
};

export const validatePassword = (password: string): boolean => {
  // Requires 8 characters, at least one uppercase, one lowercase and one number
  return /^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(
    password
  );
};
