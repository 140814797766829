import { IconButton, InputAdornment } from '@mui/material';

import {
  IconKeyMS,
  IconPersonMS,
  IconVisibilityMS,
  IconVisibilityOffMS,
} from '../constants/icons';

export const EmailIcon = {
  startAdornment: (
    <InputAdornment position="start" sx={{ color: '#FFF' }}>
      {IconPersonMS}
    </InputAdornment>
  ),
};

export function PasswordIcon(): JSX.Element {
  return (
    <InputAdornment position="start" sx={{ color: '#FFF' }}>
      {IconKeyMS}
    </InputAdornment>
  );
}

interface IconVisibilityProps {
  showPassword: boolean;
  setShowPassword: (isShowPassword: boolean) => void;
}

export function IconVisibility({
  setShowPassword,
  showPassword,
}: IconVisibilityProps): JSX.Element {
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setShowPassword(!showPassword)}
        onMouseDown={(e) => e.preventDefault()}
        edge="end"
        sx={{
          color: '#FAFCFF',
          margin: '0 6px',
          '& span': { fontSize: '22px' },
        }}
      >
        {showPassword ? IconVisibilityMS : IconVisibilityOffMS}
      </IconButton>
    </InputAdornment>
  );
}
