import {
  Box,
  Button,
  ButtonBase,
  CardMedia,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const StyledIconButton = styled(IconButton)(() => ({
  color: '#E89710',
  fontWeight: '600',
}));

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    minWidth: '900px',
    borderRadius: '16px',
    backgroundColor: '#313234',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minHeight: '600px',
  padding: '40px',
}));

export const CloseButton = styled(ButtonBase)(() => ({
  color: '#707070',
  float: 'right',
  '&:hover': {
    color: '#EDEFF2',
  },
  '&:active': {
    color: '#E89710',
  },
  '& span': { fontSize: '28px', fontWeight: '600' },
}));

export const StyledTitle = styled(DialogTitle)(() => ({
  color: '#EDEFF2',
  fontSize: '21px',
  fontWeight: '600',
  padding: '16px 0',
  textTransform: 'capitalize',
  marginBottom: '20px',
}));

export const PictureGrid = styled(Grid)(() => ({
  margin: '20px',
}));

export const PictureCardMedia = styled(CardMedia)(() => ({
  width: '320px',
  height: '250px',
  borderRadius: '10px',
}));

type UploadButtonProps = {
  component: string;
};

export const UploadButton = styled(ButtonBase)<UploadButtonProps>(
  ({ component }) => ({
    color: '#EDEFF2',
    textTransform: 'lowercase',
    fontSize: '18px',
    margin: '20px 0',
    borderRadius: '8px',
    padding: '0 10px',
    component,
    '&:hover': {
      color: '#E89710',
      textDecoration: 'underline',
    },
  })
);

export const StyledTextField = styled(TextField)(() => ({
  marginBottom: '40px',
  minWidth: '260px',
  '& .MuiInputBase-input': {
    color: '#FFF',
    fontSize: '18px',
  },
  '& .MuiInputBase-root.MuiInput-root:before': {
    borderBottom: '1px solid #8B8C8E',
  },
  '& .MuiInputBase-root.MuiInput-root:after': {
    borderBottom: '1px solid #8B8C8E',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:before': {
    borderBottom: '1px solid #EDEFF2',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:after': {
    borderBottom: '1px solid #EDEFF2',
  },
}));

export const ButtonGrid = styled(Grid)(() => ({
  justifyContent: 'space-between',
  padding: '20px',
}));

export const OutlinedButton = styled(Button)(() => ({
  border: '1.5px solid',
  borderColor: '#E89710',
  textTransform: 'lowercase',
  maxWidth: '200px',
}));

export const FilledButton = styled(Button)(() => ({
  border: '1.5px solid',
  borderColor: '#E89710',
  backgroundColor: '#E89710',
  textTransform: 'lowercase',
  color: '#EDEFF2',
  maxWidth: '200px',
}));

export const PatternFormatStyles = {
  marginBottom: '40px',
  minWidth: '260px',
  '& .MuiInputBase-input': {
    color: '#FFF',
    fontSize: '18px',
  },
  '& .MuiInputBase-root.MuiInput-root:before': {
    borderBottom: '1px solid #8B8C8E',
  },
  '& .MuiInputBase-root.MuiInput-root:after': {
    borderBottom: '1px solid #8B8C8E',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:before': {
    borderBottom: '1px solid #EDEFF2',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:after': {
    borderBottom: '1px solid #EDEFF2',
  },
};

export const UploadBox = styled(Box)(() => ({
  width: '95%',
  border: '1px solid #E89710',
  borderRadius: 12,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '&:hover': {
    cursor: 'pointer',
    borderColor: alpha('#E89710', 0.7),
    transition: '0.3s',
  },

  '& span': {
    fontSize: 20,
  },

  '& label': {
    color: '#E89710',
    fontWeight: 'bold',
    fontSize: 14,
    textTransform: 'uppercase',
    '&:hover': {
      cursor: 'pointer',
      color: alpha('#E89710', 0.7),
      transition: '0.3s',
    },
  },

  '& input': {
    width: '100%',
  },
}));
