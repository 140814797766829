/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, Grid, TextField } from '@mui/material';
import { ChangeEvent, useContext, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';

import {
  changePicture,
  deleteConstruction,
  updateART,
  updateCertificateBlockchain,
  updateConstruction,
  updateFinalReport,
} from '../../../api/constructions';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { Constants } from '../../../constants/home';
import { IconCloseMS, IconEditMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import {
  getErrorMessage,
  reverseConclusion,
  validateImg,
} from '../../../helpers';
import { useStoragedJwt } from '../../../hooks/useDecodedJwt';
import {
  ButtonGrid,
  CloseButton,
  DialogContainer,
  FilledButton,
  OutlinedButton,
  PatternFormatStyles,
  PictureCardMedia,
  PictureGrid,
  StyledDialog,
  StyledIconButton,
  StyledTextField,
  StyledTitle,
  UploadBox,
  UploadButton,
} from './styles';

interface DownloadDialogProps {
  id: number;
  lastPicture?: string;
  name: string;
  endDate: string;
  modalCallback: () => void;
}

export default function EditPictureDialog({
  id,
  lastPicture,
  name,
  endDate,
  modalCallback,
}: DownloadDialogProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [newPicture, setNewPicture] = useState('');
  const [pictureFile, setPictureFile] = useState<FormData>();
  const [certificateFile, setCertificateFile] = useState<FormData>();
  const [finalReport, setFinalReport] = useState<FormData>();
  const [constructionName, setConstructionName] = useState(name);
  const [constructionEndDate, setConstructionEndDate] = useState(endDate);
  const [art, setArt] = useState<FormData>();

  const decoded = useStoragedJwt();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateImg(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const file = e.target.files[0];
    setNewPicture(URL.createObjectURL(file));

    const formData = new FormData();
    formData.append('picture', file);
    setPictureFile(formData);
  };

  const handleChangeCertificate = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateImg(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('certificate', file);
    setCertificateFile(formData);
  };

  const handleChangeART = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0)) {
      setSnackbarMessage('Algo deu errado, tente novamente');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('art', file);
    setArt(formData);
  };

  const handleChangeFinalReport = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0)) {
      setSnackbarMessage('Algo deu errado, tente novamente');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append('report', file);
    setFinalReport(formData);
  };

  const handleSubmit = async (): Promise<void> => {
    setLoading(true);
    if (pictureFile) {
      try {
        const response = await changePicture(id, pictureFile);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente');
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }

    if (certificateFile) {
      try {
        const response = await updateCertificateBlockchain(id, certificateFile);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente');
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }

    if (finalReport) {
      try {
        const response = await updateFinalReport(id, finalReport);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente');
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }

    if (art) {
      try {
        const response = await updateART(id, art);
        if (response.detail.description) {
          throw new Error(response.detail.description);
        }
        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente');
        }
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }

    const constructionData = {
      name: constructionName,
      finishes_at: reverseConclusion(constructionEndDate),
    };

    try {
      const response = await updateConstruction(id, constructionData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      setLoading(false);
      setSnackbarMessage('Obra atualizada!');
      setOpenSnackbar(true);
      setErrorMessage(false);
      setTimeout(() => {
        handleClose();
        modalCallback();
      }, 1500);
    } catch (error) {
      setLoading(false);
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
      handleClose();
    }
  };

  const handleDelete = async (): Promise<void> => {
    try {
      const response = await deleteConstruction(id);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      setSnackbarMessage(`Obra ${constructionName} foi excluída da sua lista`);
      setOpenSnackbar(true);
      setErrorMessage(false);
      setTimeout(() => {
        modalCallback();
        navigate('/home');
        handleClose();
      }, 2000);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
      handleClose();
    }
  };

  return (
    <>
      <StyledIconButton onClick={handleOpen}>{IconEditMS}</StyledIconButton>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="edit construction"
      >
        <DialogContainer>
          <CloseButton disableTouchRipple onClick={handleClose}>
            {IconCloseMS}
          </CloseButton>
          <StyledTitle>{Constants.editConstruction}</StyledTitle>
          <PictureGrid container>
            <Grid item xs={6}>
              {newPicture ? (
                <PictureCardMedia image={newPicture} title="picture" />
              ) : (
                !newPicture &&
                lastPicture && (
                  <PictureCardMedia image={lastPicture} title="picture" />
                )
              )}
              <label htmlFor="uploadLogo">
                <input
                  accept="image/*"
                  id="uploadLogo"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                    handleFileUpload(e)
                  }
                />
                <UploadButton component="span">
                  {Constants.changePicture}
                </UploadButton>
              </label>
            </Grid>
            <Grid item xs={6}>
              <StyledTextField
                id="email"
                variant="standard"
                placeholder="Nome da Obra"
                helperText="Digite para trocar o nome"
                value={constructionName}
                onChange={(e) => setConstructionName(e.target.value)}
              />
              <PatternFormat
                customInput={TextField}
                id="end-date"
                variant="standard"
                placeholder="Data de Conclusão"
                helperText="Digite para trocar a data de conclusão"
                format="##/##/####"
                value={constructionEndDate}
                onChange={(e) => setConstructionEndDate(e.target.value)}
                sx={PatternFormatStyles}
              />
            </Grid>
            {decoded?.user.role !== 2 && (
              <>
                <Grid item xs={4}>
                  <UploadBox>
                    <label htmlFor="final_report">
                      Upload relatório final
                      <input
                        id="final_report"
                        accept="application/pdf"
                        multiple={false}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                          handleChangeFinalReport(e)
                        }
                      />
                    </label>
                  </UploadBox>
                </Grid>
                <Grid item xs={5}>
                  <UploadBox>
                    <label htmlFor="certificate">
                      Upload certificado blockchain
                      <input
                        id="certificate"
                        multiple={false}
                        accept="image/*"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                          handleChangeCertificate(e)
                        }
                      />
                    </label>
                  </UploadBox>
                </Grid>
                <Grid item xs={3}>
                  <UploadBox>
                    <label htmlFor="art">
                      Upload da art
                      <input
                        id="art"
                        multiple={false}
                        accept="application/pdf"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                          handleChangeART(e)
                        }
                      />
                    </label>
                  </UploadBox>
                </Grid>
              </>
            )}
          </PictureGrid>
          <ButtonGrid container>
            <Grid item xs={5}>
              <ConfirmationDialog
                title={Constants.deleteConstruction}
                text={constructionName}
                modalCallback={handleDelete}
              />
            </Grid>
            <Grid item xs={3}>
              <OutlinedButton onClick={handleClose}>
                {Constants.cancel}
              </OutlinedButton>
            </Grid>
            <Grid item xs={3}>
              <FilledButton onClick={handleSubmit}>
                {loading ? <CircularProgress size={24} /> : Constants.save}
              </FilledButton>
            </Grid>
          </ButtonGrid>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
