/* eslint-disable camelcase */
import {
  BaseUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../global';
import {
  ChangeLocationProps,
  CreateConstruction,
  DetailResponse,
  GetAllConstructionsProps,
  GetConstructionProps,
  GetDashboardProps,
  GetInspectionsProps,
  SearchConstructionsProps,
  UpdateConstructionData,
} from './types';

export const createConstruction = (
  name: string,
  finishes_at: string,
  company_id: number
): Promise<CreateConstruction> => {
  const data = JSON.stringify({
    name,
    finishes_at,
    company_id,
  });
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: data,
  };

  return fetch(`${BaseUrl}/api/V1/constructions`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getAllConstructions = (
  id: number,
  page: number,
  size: number
): Promise<GetAllConstructionsProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/companies/${id}/constructions?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const searchConstructions = (
  searchString: string,
  page: number,
  size: number
): Promise<SearchConstructionsProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    // eslint-disable-next-line max-len
    `${BaseUrl}/api/V1/constructions/search_string=${searchString}&page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getConstruction = (id: number): Promise<GetConstructionProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/constructions/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getInspections = (
  id: number,
  page: number,
  rows: number
): Promise<GetInspectionsProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/constructions/${id}/inspections?page=${page}&size=${rows}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getDashboard = (id: number): Promise<GetDashboardProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/constructions/${id}/dashboard`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const changePicture = (
  id: number,
  data: FormData
): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: data,
  };
  return fetch(`${BaseUrl}/api/V1/constructions/${id}/picture`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updateConstruction = (
  id: number,
  data: UpdateConstructionData
): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  return fetch(`${BaseUrl}/api/V1/constructions/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const deleteConstruction = (id: number): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/constructions/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const changeLocation = (
  id: number,
  data: FormData
): Promise<ChangeLocationProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: data,
  };
  return fetch(`${BaseUrl}/api/V1/constructions/${id}/location`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const deleteLocationImage = (id: number): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/constructions/${id}/location`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updateCertificateBlockchain = (
  id: number,
  certificate: FormData
): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: certificate,
  };
  return fetch(
    `${BaseUrl}/api/V1/constructions/${id}/blockchain-certificate`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updateFinalReport = (
  id: number,
  report: FormData
): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: report,
  };
  return fetch(`${BaseUrl}/api/V1/constructions/${id}/report`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updateART = (
  id: number,
  art: FormData
): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: art,
  };
  return fetch(`${BaseUrl}/api/V1/constructions/${id}/art`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
export const getART = (id: number): Promise<Uint8Array> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  return fetch(`${BaseUrl}/api/V1/constructions/${id}/art`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.arrayBuffer();
    })
    .then((buffer) => new Uint8Array(buffer))
    .catch((error) => {
      throw new Error(error.message);
    });
};
