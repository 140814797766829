export enum ReportStatus {
  LETTER_SENT = 1,
  SCHEDULED,
  RESCHEDULED_OCCUPANT,
  RESCHEDULED_REAL_PRICE,
  MAKING_REPORT,
  COMPLETED,
  NO_CONTACT,
  NOT_APPROVED_CLIENT,
  CANCELED_CLIENT,
  REJECTED_OCCUPANT,
  NOTIFICATION,
  TELEGRAM,
}

export const convertReportStatus = (enumKind: ReportStatus): string => {
  let word = '';
  switch (enumKind) {
    case ReportStatus.LETTER_SENT:
      word = 'carta enviada';
      break;
    case ReportStatus.SCHEDULED:
      word = 'vistoria agendada';
      break;
    case ReportStatus.RESCHEDULED_OCCUPANT:
      word = 'remarcada pelo ocupante';
      break;
    case ReportStatus.RESCHEDULED_REAL_PRICE:
      word = 'remarcada pela Real Price';
      break;
    case ReportStatus.MAKING_REPORT:
      word = 'produzindo relatório';
      break;
    case ReportStatus.COMPLETED:
      word = 'concluída';
      break;
    case ReportStatus.NO_CONTACT:
      word = 'contato sem sucesso';
      break;
    case ReportStatus.NOT_APPROVED_CLIENT:
      word = 'não aprovada pelo cliente';
      break;
    case ReportStatus.CANCELED_CLIENT:
      word = 'cancelada pelo cliente';
      break;
    case ReportStatus.REJECTED_OCCUPANT:
      word = 'rejeitado pelo ocupante';
      break;
    case ReportStatus.NOTIFICATION:
      word = 'notificação via cartório';
      break;
    case ReportStatus.TELEGRAM:
      word = 'notificação via telegrama';
      break;
    default:
      word = '';
  }
  return word;
};
