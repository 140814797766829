/* eslint-disable camelcase */
import {
  BaseUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../global';
import { DetailResponse } from '../users/types';
import {
  GetInspectionProps,
  InspectionData,
  InspectionObservation,
} from './types';

export const getInspection = (id: number): Promise<GetInspectionProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/inspections/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const changeInspection = (
  id: number,
  address: string,
  number: string,
  complement: string,
  accountable_person: string,
  phone: string,
  engineer: string,
  status: number,
  finishes_at: string | null,
  inspection_performed: boolean
): Promise<InspectionData> => {
  const data = JSON.stringify({
    id,
    address,
    number,
    complement,
    accountable_person,
    phone,
    engineer,
    status,
    finishes_at,
    inspection_performed,
  });
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: data,
  };
  return fetch(`${BaseUrl}/api/V1/inspections/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const editInspectionObs = (
  id: number,
  observations: string
): Promise<InspectionObservation> => {
  const data = JSON.stringify({ observations });
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: data,
  };

  return fetch(`${BaseUrl}/api/V1/inspections/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const downloadInspectionXlsx = (id: number): Promise<void> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  return (
    fetch(
      `${BaseUrl}/api/V1/constructions/${id}/inspections/xlsx`,
      requestOptions
    )
      // eslint-disable-next-line consistent-return
      .then((response) => {
        if (response.ok) {
          const contentDisposition = response.headers
            .get('Content-Disposition')
            ?.split('filename=')[1];
          const filename = contentDisposition || 'file.xlsx';
          return response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
          });
        }
        throw new Error('Failed to download the file');
      })
      .catch((error) => {
        throw new Error(error);
      })
  );
};

export const uploadInspectionXLSX = (
  data: FormData
): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithToken(),
    body: data,
  };

  return fetch(`${BaseUrl}/api/V1/inspections/xlsx`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const newInspectionReport = (
  data: FormData
): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithToken(),
    body: data,
  };
  return fetch(`${BaseUrl}/api/V1/reports`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
