/* eslint-disable react/require-default-props */
import { Grid, TextField, CircularProgress } from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import { PatternFormat } from 'react-number-format';

import { createConstruction } from '../../../api/constructions';
import { IconCloseMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage, reverseConclusion } from '../../../helpers';
import {
  ButtonGrid,
  CloseButton,
  DialogContainer,
  FilledButton,
  OutlinedButton,
  PatternFormatStyles,
  PictureGrid,
  StyledDialog,
  StyledTextField,
  StyledTitle,
} from './styles';

interface NewConstructionProps {
  open: boolean;
  onClose: () => void;
  id: number;
  handleReload: () => void;
}

function NewConstruction({
  open,
  onClose,
  id,
  handleReload,
}: NewConstructionProps): JSX.Element {
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [finishesAt, setFinishesAt] = useState('');

  const { setSnackbarMessage, setOpenSnackbar, setErrorMessage } =
    useContext(GlobalContext);

  const handleClose = (): void => {
    onClose();
  };

  const clearFields = (): void => {
    setName('');
    setFinishesAt('');
  };

  const handleSubmit = useCallback(async (): Promise<void> => {
    setLoading(true);
    const finishesAtFormated = reverseConclusion(finishesAt);
    if (id) {
      try {
        const response = await createConstruction(name, finishesAtFormated, id);

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente!');
        }

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        setSnackbarMessage('Sucesso!');
        setOpenSnackbar(true);
        setErrorMessage(false);
        setLoading(false);
        setTimeout(() => {
          clearFields();
          onClose();
          handleReload();
        }, 1000);
      } catch (error) {
        setLoading(false);
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    } else {
      setSnackbarMessage('Selecione uma empresa');
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, [
    finishesAt,
    handleReload,
    id,
    name,
    onClose,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
  ]);

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="create construction"
    >
      <DialogContainer>
        <CloseButton disableTouchRipple onClick={handleClose}>
          {IconCloseMS}
        </CloseButton>
        <StyledTitle>nova obra</StyledTitle>
        <PictureGrid container>
          <Grid item xs={10}>
            <StyledTextField
              id="email"
              variant="standard"
              placeholder="Nome da Obra"
              helperText="Digite para trocar o nome"
              onChange={(e) => setName(e.target.value)}
            />
            <PatternFormat
              customInput={TextField}
              id="end-date"
              variant="standard"
              placeholder="Data de Conclusão"
              helperText="Digite para trocar a data de conclusão"
              format="##/##/####"
              onChange={(e) => setFinishesAt(e.target.value)}
              sx={PatternFormatStyles}
            />
          </Grid>
        </PictureGrid>
        <ButtonGrid container>
          <Grid item xs={3}>
            <OutlinedButton onClick={handleClose}>cancelar</OutlinedButton>
          </Grid>
          <Grid item xs={3}>
            <FilledButton onClick={handleSubmit}>
              {loading ? <CircularProgress size={24} /> : 'Salvar'}
            </FilledButton>
          </Grid>
        </ButtonGrid>
      </DialogContainer>
    </StyledDialog>
  );
}

export default NewConstruction;
