export const Constants = {
  companies: 'Empresas',
  panel: 'Painel de empresas',
  ref: 'Ref',
  name: 'Nome da empresa',
  address: 'Endereço',
  contact: 'Contato',
  responsible: 'Responsável',
  cnpj: 'CNPJ',
  actions: 'Ações',
};
