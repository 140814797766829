import {
  Box,
  Table,
  TableCell,
  TablePagination,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TableCellProps } from '@mui/material/TableCell';
import { Component } from 'react';

export const StyledTable = styled(Table)(() => ({
  borderCollapse: 'separate',
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
}));

export const RowHeader = styled(TableRow)(() => ({
  backgroundColor: '#0A1123',
  borderTopRightRadius: '10px',
}));

interface CellHeaderProps extends TableCellProps {
  minwidth?: string;
  bleft?: boolean;
  bright?: boolean;
}

export const CellHeader = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'bleft' && prop !== 'bright',
})<CellHeaderProps>(({ minwidth, bleft, bright }) => ({
  border: '1px solid #2B3550',
  fontSize: '16px',
  fontWeight: '600',
  color: '#F2F2F2',
  minwidth,
  ...(bleft && {
    borderTopLeftRadius: '10px',
  }),
  ...(bright && {
    borderTopRightRadius: '10px',
  }),
}));

export const RowContent = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#171D2D',
  },
  '&:hover': {
    backgroundColor: '#19305E',
    cursor: 'pointer',
  },
}));

type CellContentProps = {
  color?: string;
};

export const CellContent = styled(TableCell)<CellContentProps>(({ color }) => ({
  border: '1px solid #2B3550',
  fontSize: '16px',
  color,
}));

type StyledTablePaginationProps = {
  component: string;
};

export const StyledTablePagination = styled(
  TablePagination
)<StyledTablePaginationProps>(() => ({
  color: '#F2F2F2',
  marginBottom: '10px',
  component: Component,
  '& .MuiTablePagination-selectLabel': {
    color: '#F2F2F2',
  },
  '& .MuiInputBase-root': {
    '& .MuiSvgIcon-root': {
      color: '#F2F2F2',
    },
  },
  '& .MuiTablePagination-displayedRows': {
    color: '#F2F2F2',
  },
  '& .MuiButtonBase-root': {
    backgroundColor: '#313234',
    color: '#F2F2F2',
    margin: '0 8px',
    '&.Mui-disabled': {
      backgroundColor: '#0A1123',
      color: '#8B8C8E',
    },
    '&:hover': {
      color: '#E89710',
      backgroundColor: '#313234',
    },
    '& .MuiSvgIcon-root': {
      '&:hover': {
        color: '#E89710',
      },
    },
  },
}));

export const DialogDisabled = styled(Box)(() => ({
  padding: '0 6px',
  width: '100%',
  display: 'flex',
  gap: '8px',
  cursor: 'default',
  color: '#585c67',
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    color: '#8B8C8E',
    fontSize: 12,
    fontWeight: '400',
    padding: '2px 4px',
    textTransform: 'lowercase',
    marginTop: '-2px !important',
    '@media (min-width: 1980px)': {
      display: 'none',
    },
  },
}));
