import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogTitle,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogButton = styled(Button)(() => ({
  width: '200px',
  backgroundColor: '#4E89F6',
  color: '#EDEFF2',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#2981f4',
    color: '#FFFFFF',
  },
  '&:active': {
    backgroundColor: '#4E89F6',
    color: '#FFFFFF',
  },
}));

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    backgroundColor: '#313234',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: '600px',
  minHeight: '400px',
  padding: '40px',
}));

export const CloseButton = styled(ButtonBase)(() => ({
  color: '#707070',
  float: 'right',
  '&:hover': {
    color: '#EDEFF2',
  },
  '&:active': {
    color: '#E89710',
  },
  '& span': { fontSize: '28px', fontWeight: '600' },
}));

export const StyledTitle = styled(DialogTitle)(() => ({
  color: '#EDEFF2',
  fontSize: '21px',
  fontWeight: '600',
  padding: '16px 0',
  textTransform: 'capitalize',
}));

export const LogoBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '30px',
  padding: '40px',
  border: '1px solid #8B8C8E',
  borderRadius: '10px',
  backgroundColor: '#1A1A1A',
  width: '100%',
}));

export const LogoTypography = styled(Typography)(() => ({
  margin: '20px',
}));

export const UploadBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '40px',
  margin: '40px 20px 20px',
}));

type UploadButtonProps = {
  component: string;
};

export const UploadButton = styled(Button)<UploadButtonProps>(
  ({ component }) => ({
    border: '1.5px solid',
    borderColor: '#E89710',
    textTransform: 'lowercase',
    component,
  })
);

export const SaveButton = styled(Button)(() => ({
  border: '1.5px solid',
  borderColor: '#E89710',
  backgroundColor: '#E89710',
  textTransform: 'lowercase',
  color: '#EDEFF2',
}));
