import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#313234',
      light: '#8B8C8E',
    },
    secondary: {
      main: '#E67715',
      light: '#E89710',
    },
    common: {
      white: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: `"Montserrat", "Roboto"`,
    allVariants: {
      color: '#8B8C8E',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          width: '280px',
          height: '48px',
          textTransform: 'none',
          backgroundColor: 'none',
          color: '#E89710',
          fontFamily: 'Montserrat',
          boxShadow: 'none',
          fontSize: '16px',
          fontWeight: '600',
          '&:hover': {
            backgroundColor: '#E67715',
            color: '#FFFFFF',
          },
          '&:active': {
            backgroundColor: '#E67715',
            color: '#FFFFFF',
          },
          '&:disabled': {
            opacity: '40%',
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          ':hover': {
            color: 'white',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          minWidth: '1280px',
          maxWidth: '1920px',
          minHeight: '800px',
          height: '100%',
          margin: '0 auto',
        },
        body: {
          backgroundColor: '#182030',
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
      },
    },
  },
});
