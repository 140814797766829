import {
  Box,
  CardMedia,
  ListItem,
  tooltipClasses,
  TooltipProps,
  Tooltip,
  BoxProps,
  List,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import ListItemButton, {
  ListItemButtonProps,
} from '@mui/material/ListItemButton';
import ListItemIcon, { ListItemIconProps } from '@mui/material/ListItemIcon';
import { styled, Theme, CSSObject } from '@mui/material/styles';

export const openedMixin = (theme: Theme): CSSObject => ({
  width: '220px',
  zIndex: 20,
  backgroundColor: '#313234',
  overflowX: 'visible',
  overflowY: 'visible',
  borderRight: '1px solid #8B8C8E',
  flexDirection: 'row',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

export const closedMixin = (theme: Theme): CSSObject => ({
  width: '102px',
  zIndex: 20,
  backgroundColor: '#313234',
  overflowX: 'visible',
  overflowY: 'visible',
  borderRight: '1px solid #8B8C8E',
  flexDirection: 'row',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: '220px',
  position: 'fixed',
  height: '100%',
  left: '0',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const BoxContainer = styled(Box)(() => ({
  position: 'absolute',
  height: '100%',
}));

interface ArrowBoxProps extends BoxProps {
  open?: boolean;
}

export const ArrowBox = styled(Box)<ArrowBoxProps>(({ theme, open }) => ({
  height: '90px',
  padding: '4px',
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  backgroundColor: '#313234',
  color: '#8B8C8E',
  position: 'fixed',
  top: '50.2vh',
  cursor: 'pointer',
  [theme.breakpoints.down('lg')]: {
    position: 'absolute',
  },
  [theme.breakpoints.up('xl')]: {
    position: 'absolute',
  },
  ...(open && {
    width: '229.9px',
    left: '10px',
    clipPath:
      'polygon(20% 0%, 91.5% 0%, 100% 25%, 100% 75%, 91.5% 100%, 20% 100%, 0% 80%, 0% 20%)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    width: '109px',
    left: '10px',
    clipPath:
      'polygon(20% 0%, 84% 0%, 100% 25%, 100% 75%, 84% 100%, 20% 100%, 0% 80%, 0% 20%)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
}));

export const BackArrowBox = styled(Box)<ArrowBoxProps>(({ theme, open }) => ({
  position: 'fixed',
  top: '50vh',
  height: '94px',
  [theme.breakpoints.down('lg')]: {
    position: 'absolute',
  },
  [theme.breakpoints.up('xl')]: {
    position: 'absolute',
  },
  ...(open && {
    width: '231px',
    left: '10px',
    background: 'linear-gradient(90deg, #313234 90.7%, #8B8C8E 0)',
    clipPath:
      'polygon(20% 0%, 91% 0%, 100% 25%, 100% 74%, 91% 100%, 20% 100%, 0% 80%, 0% 20%)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    width: '110px',
    left: '10px',
    background: 'linear-gradient(90deg, #313234 83%, #8B8C8E 0)',
    clipPath:
      'polygon(20% 0%, 83% 0%, 100% 25%, 100% 74%, 83% 100%, 20% 100%, 0% 80%, 0% 20%)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
}));

export const DrawerHeader = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '30px 25px',
}));

export const StyledLogo = styled(CardMedia)(() => ({
  width: '55px',
  height: '55px',
}));

export const MenuList = styled(List)(() => ({
  minHeight: '620px',
  height: '85%',
  width: '95px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 20,
}));

export const MenuListItem = styled(ListItem)(() => ({
  display: 'block',
}));

interface MenuButtonProps extends ListItemButtonProps {
  open?: boolean;
}

export const MenuButton = styled(ListItemButton)<MenuButtonProps>(
  ({ open }) => ({
    minHeight: 48,
    width: 48,
    px: '12px',
    '&:hover': {
      backgroundColor: '#313234',
    },
    ...(open && {
      justifyContent: 'initial',
      margin: '15px 30px',
    }),
    ...(!open && {
      justifyContent: 'center',
      margin: '15px auto',
    }),
  })
);

interface MenuIconProps extends ListItemIconProps {
  open?: boolean;
}

export const MenuIcon = styled(ListItemIcon)<MenuIconProps>(({ open }) => ({
  justifyContent: 'center',
  minWidth: 0,
  gap: '12px',
  ...(open && {
    mr: 3,
  }),
  ...(!open && {
    mr: 'auto',
  }),
}));

export const LogoutItem = styled(ListItem)(() => ({
  display: 'block',
  color: '#8B8C8E',
  width: '80px',
  position: 'fixed',
  bottom: '44px',
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#313234',
    color: '#8B8C8E',
    fontSize: 12,
    fontWeight: '400',
    padding: '2px 4px',
    marginTop: '-10px !important',
    '@media (min-width: 1980px)': {
      display: 'none',
    },
  },
}));

export const active = {
  color: '#E89710',
  textDecoration: 'none',
};

export const inactive = {
  color: '#8B8C8E',
  textDecoration: 'none',
};
