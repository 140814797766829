export const BaseUrl = 'https://cautelar-api.realprice.com.br';

export const myHeadersWithTokenContentType = (): Headers => {
  const token = localStorage.getItem('precautionary-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  return myHeaders;
};

export const myHeadersWithToken = (): Headers => {
  const token = localStorage.getItem('precautionary-token');
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  return myHeaders;
};
