/* eslint-disable camelcase */
import { BaseUrl, myHeadersWithTokenContentType } from '../global';
import {
  GetAllReportsProps,
  GetReportProps,
  NewInspectionProps,
} from './types';

export const getAllReports = (
  id: number,
  page: number,
  size: number
): Promise<GetAllReportsProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/inspections/${id}/reports?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getReport = (id: number): Promise<GetReportProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/reports/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const newInspection = (
  address: string,
  number: string,
  complement: string,
  accountable_person: string,
  phone: string,
  engineer: string,
  status: number,
  observations: string,
  finishes_at: string | null,
  construction_id: number
): Promise<NewInspectionProps> => {
  const data = JSON.stringify({
    address,
    number,
    complement,
    accountable_person,
    phone,
    engineer,
    status,
    observations,
    finishes_at,
    construction_id,
  });
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: data,
  };
  return fetch(`${BaseUrl}/api/V1/inspections`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
