import { Box, ButtonBase, CardMedia, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    minWidth: '70%',
    borderRadius: 16,
    backgroundColor: '#313234',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minHeight: 400,
  padding: 40,
}));

export const CloseButton = styled(ButtonBase)(() => ({
  color: '#707070',
  float: 'right',
  '&:hover': {
    color: '#EDEFF2',
  },
  '& span': {
    fontSize: 28,
    fontWeight: 600,
  },
}));

export const ContainerCardMedia = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: '16px auto',
}));

export const PictureCardMedia = styled(CardMedia)(() => ({
  width: '90%',
  height: 570,
  borderRadius: 10,
  objectFit: 'contain',
}));
