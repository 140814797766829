import { CircularProgress, Typography } from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { newInspectionReport } from '../../../../api/inspections';
import { getAllReports, getReport } from '../../../../api/reports';
import { ReportsData } from '../../../../api/reports/types';
import {
  IconCloseMS,
  IconDownloadMS,
  IconPublishMS,
} from '../../../../constants/icons';
import { Constants } from '../../../../constants/reports';
import { GlobalContext } from '../../../../context/global';
import { convertDate, getErrorMessage } from '../../../../helpers';
import { useStoragedJwt } from '../../../../hooks/useDecodedJwt';
import {
  CloseButton,
  DialogButton,
  DialogContainer,
  DialogDisabled,
  DownloadButton,
  Label,
  RefContainer,
  RefNumber,
  ReportDate,
  ReportTitle,
  StyledDialog,
  StyledList,
  StyledListItem,
  StyledTitle,
  StyledTooltip,
  UploadButton,
} from './styles';

interface DownloadDialogProps {
  id: number;
  reference: number;
}

export default function DownloadDialog({
  id,
  reference,
}: DownloadDialogProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [reportsData, setReportsData] = useState<ReportsData[]>([]);
  const [page, setPage] = useState(1);
  const [report, setReport] = useState<FormData>();
  const [loading, setLoading] = useState(false);

  const decoded = useStoragedJwt();

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const reportsPerPage = 12;

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      return;
    }

    try {
      const response = await getAllReports(id, page, reportsPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      if (response.data) {
        setReportsData(response.data);
        setLastCalledPage(page);
      }

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, [
    page,
    lastCalledPage,
    id,
    setSnackbarMessage,
    setOpenSnackbar,
    setErrorMessage,
  ]);

  const handleAddNewReport = useCallback(async () => {
    if (report !== undefined) {
      setLoading(true);
      try {
        const response = await newInspectionReport(report);

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente!');
        }

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }
        setLoading(false);
        setReport(undefined);
        setSnackbarMessage('Sucesso!');
        setOpenSnackbar(true);
        setErrorMessage(false);
        getDataCallback();
        setLastCalledPage(0);
      } catch (error) {
        setLoading(false);
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }
  }, [
    getDataCallback,
    report,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
  ]);

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0)) {
      setSnackbarMessage('Algo deu errado, tente novamente!');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }
    const file = e.target.files[0];

    const fd = new FormData();
    fd.append('report_file', file);
    fd.append('inspection_id', String(id));
    fd.append('name', `Relatório ${reportsData.length + 1}`);
    setReport(fd);
  };

  useEffect(() => {
    if (report !== undefined) {
      handleAddNewReport();
    }
  }, [report, handleAddNewReport]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const downloadFile = (dataLink: string, name: string): void => {
    const linkSource = dataLink;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = name;
    downloadLink.click();
  };

  const handleFileDownload = async (fileId: number): Promise<void> => {
    try {
      const response = await getReport(fileId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      if (response.data) {
        downloadFile(response.data.download_url, response.data.name);
        setSnackbarMessage('Download realizado com sucesso!');
        setOpenSnackbar(true);
        setErrorMessage(false);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  return (
    <>
      {reportsData.length > 0 ? (
        <StyledTooltip title={Constants.download}>
          <DialogButton disableTouchRipple onClick={handleOpen}>
            {IconDownloadMS}
          </DialogButton>
        </StyledTooltip>
      ) : (
        <StyledTooltip title={Constants.noDownload}>
          {decoded?.user.role !== 2 ? (
            <DialogButton
              disableTouchRipple
              onClick={handleOpen}
              className="disabled"
            >
              {IconDownloadMS}
            </DialogButton>
          ) : (
            <DialogDisabled>{IconDownloadMS}</DialogDisabled>
          )}
        </StyledTooltip>
      )}
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="download vistorias"
      >
        <DialogContainer>
          <CloseButton disableTouchRipple onClick={handleClose}>
            {IconCloseMS}
          </CloseButton>
          <StyledTitle>{Constants.reports}</StyledTitle>
          <RefContainer>
            <RefNumber>
              {Constants.refDialog}
              {reference}
            </RefNumber>
            {decoded?.user.role !== 2 && (
              <Label htmlFor="upload-input">
                <UploadButton
                  id="upload-input"
                  type="file"
                  multiple={false}
                  accept=".pdf"
                  onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                    handleFileUpload(e)
                  }
                />
                {!loading ? (
                  <Typography variant="body1">
                    {IconPublishMS} upload de relatório
                  </Typography>
                ) : (
                  <CircularProgress size={18} color="info" />
                )}
              </Label>
            )}
          </RefContainer>
          <StyledList>
            {reportsData?.map((report) => (
              <StyledListItem key={report.id}>
                <ReportTitle>{report.name}</ReportTitle>
                <ReportDate>
                  {report.created_at && convertDate(report.created_at)}
                </ReportDate>
                <DownloadButton onClick={() => handleFileDownload(report.id)}>
                  {IconDownloadMS}
                  {Constants.download}
                </DownloadButton>
              </StyledListItem>
            ))}
          </StyledList>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
