/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getConstruction } from '../../api/constructions';
import { ConstructionDetails } from '../../api/constructions/types';
import { BaseUrl, myHeadersWithToken } from '../../api/global';
import construction from '../../assets/images/construction.png';
import { SampleDialog } from '../../components/Dialog/SampleDialog';
import Menu from '../../components/Menu';
import {
  CloseButton,
  ContainerCardMedia,
  DialogContainer,
  StyledDialog,
} from '../../components/Modal/SampleModal/styles';
import Snackbar from '../../components/Snackbar';
import {
  IconCloseMS,
  IconPieChartMS,
  IconTableViewMS,
} from '../../constants/icons';
import { Constants } from '../../constants/reports';
import { GlobalContext } from '../../context/global';
import {
  convertDate,
  convertTime,
  formatConclusion,
  getErrorMessage,
} from '../../helpers';
import { useStoragedJwt } from '../../hooks/useDecodedJwt';
import EditPictureDialog from '../Home/PictureDialog';
import { Dashboard } from './Dashboard';
import { NewReportModal } from './NewReportModal';
import { PdfViewer } from './PdfViewer';
import {
  CertificateButton,
  ConclusionTypography,
  ConstructionName,
  ContainedButton,
  ContainerButtons,
  DateTypography,
  DownloadReportButton,
  InfoBox,
  MiddleBox,
  PictureCardMedia,
  StyledGrid,
  SubtitleTypography,
  TitleBox,
  TitleTypography,
} from './styles';
import { ReportsTable } from './Table';

export function Reports(): JSX.Element {
  const [constructionData, setConstructionData] =
    useState<ConstructionDetails>();
  const [reportLink, setReportLink] = useState<string | undefined>();
  const {
    openSnackbar,
    setOpenSnackbar,
    setErrorMessage,
    setSnackbarMessage,
    dashLabel,
  } = useContext(GlobalContext);
  const { id } = useParams();
  const reportId = parseInt(id as string, 10);
  const decoded = useStoragedJwt();
  const constructionId = parseInt(id as string, 10);

  const [open, setOpen] = useState(false);
  const [openNewReportModal, setOpenNewReportModal] = useState(false);

  const [openModalBlockchain, setOpenModalBlockchain] = useState(false);
  const [filterURL, setFilterURL] = useState('');

  const [openARTDialog, setOpenARTDialog] = useState(false);

  const getDataCallback = useCallback(async () => {
    try {
      const response = await getConstruction(constructionId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      if (response.data) {
        setConstructionData(response.data);
        setReportLink(response.data?.report);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, []);

  const downloadReport = (): void => {
    window.open(reportLink);
  };

  const getDataWithFilter = (): void => {
    const len = dashLabel.length;
    if (len === 1) {
      setFilterURL(`filter_status=${dashLabel[0]}`);
    } else if (len > 1) {
      let a = '';
      for (let i = 0; i < dashLabel.length; i += 1) {
        a += `&filter_status=${dashLabel[i]}`;
      }
      setFilterURL(a);
    }
  };

  // eslint-disable-next-line consistent-return
  const exportXLSX = useCallback(async () => {
    let URL = `${BaseUrl}/api/V1/constructions/${id}/inspections/xlsx?`;
    if (filterURL !== '') {
      URL += filterURL;
      const requestOptions = {
        method: 'GET',
        headers: myHeadersWithToken(),
      };
      return (
        fetch(`${URL}`, requestOptions)
          // eslint-disable-next-line consistent-return
          .then((response) => {
            if (response.ok) {
              const contentDisposition = response.headers
                .get('Content-Disposition')
                ?.split('filename=')[1];
              const filename = contentDisposition || 'file.xlsx';
              return response.blob().then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(url);
              });
            }
            throw new Error('Failed to download the file');
          })
          .catch((error) => {
            throw new Error(error);
          })
      );
    }
  }, [filterURL]);

  useEffect(() => {
    getDataWithFilter();
  }, [dashLabel]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  return (
    <>
      <Menu />
      <StyledGrid>
        <TitleTypography>
          {IconPieChartMS}
          {Constants.reports}
        </TitleTypography>
        <TitleBox>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 8,
            }}
          >
            <ConstructionName>{constructionData?.name}</ConstructionName>
            {constructionData && (
              <EditPictureDialog
                id={constructionData?.id}
                lastPicture={
                  constructionData?.picture
                    ? constructionData?.picture
                    : construction
                }
                name={constructionData?.name}
                endDate={formatConclusion(constructionData?.finishes_at)}
                modalCallback={getDataCallback}
              />
            )}
          </div>

          <InfoBox>
            <ConclusionTypography>
              {Constants.deadline}{' '}
              {constructionData?.finishes_at &&
                convertDate(constructionData.finishes_at)}
            </ConclusionTypography>
            <DateTypography>
              {Constants.lastUpdate}{' '}
              {constructionData?.inspections_last_update &&
                convertDate(constructionData.inspections_last_update)}{' '}
              {constructionData?.inspections_last_update &&
                convertTime(constructionData.inspections_last_update)}
            </DateTypography>
            <SampleDialog
              id={constructionId}
              open={open}
              closeModal={() => setOpen(false)}
            />
          </InfoBox>
        </TitleBox>
        <ContainerButtons>
          {!reportLink ? null : (
            <DownloadReportButton
              disableTouchRipple
              disabled={!reportLink}
              onClick={downloadReport}
            >
              {Constants.downloadButton}
            </DownloadReportButton>
          )}
          {!constructionData?.blockchain_certificate ? null : (
            <CertificateButton
              disabled={!constructionData?.blockchain_certificate}
              onClick={() => setOpenModalBlockchain(!openModalBlockchain)}
            >
              {Constants.certificate}
            </CertificateButton>
          )}
          {!constructionData?.art ? null : (
            <CertificateButton
              style={{ width: 'auto', padding: '0 32px' }}
              disabled={!constructionData?.blockchain_certificate}
              onClick={() => setOpenARTDialog(!openARTDialog)}
            >
              {Constants.art}
            </CertificateButton>
          )}
        </ContainerButtons>
        <MiddleBox>
          {/* eslint-disable-next-line no-nested-ternary */}
          {constructionData?.location ? (
            <PictureCardMedia
              image={constructionData?.location}
              title="Imagem da localização"
              onClick={() => setOpen(true)}
            />
          ) : decoded?.user.role !== 2 ? (
            <div style={{ width: 1250 }}>
              <ContainedButton onClick={() => setOpen(true)}>
                Adicionar imagem da localização
              </ContainedButton>
            </div>
          ) : (
            <div style={{ width: 1250 }} />
          )}
          <Dashboard chart />
        </MiddleBox>
        <Dashboard dashboard />
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 12 }}>
          <Button variant="outlined" onClick={() => exportXLSX()}>
            {IconTableViewMS} exportar em XLSX
          </Button>
          {decoded?.user.role !== 2 && (
            <Button
              variant="contained"
              onClick={() => setOpenNewReportModal(!openNewReportModal)}
            >
              {Constants.addReport}
            </Button>
          )}
        </div>
        <TitleBox>
          <SubtitleTypography>{Constants.inspection}</SubtitleTypography>
        </TitleBox>
        <ReportsTable />
      </StyledGrid>
      {openSnackbar && <Snackbar />}
      <NewReportModal
        open={openNewReportModal}
        closeModal={() => setOpenNewReportModal(!openNewReportModal)}
      />
      <StyledDialog
        open={openModalBlockchain}
        onClose={() => setOpenModalBlockchain(!openModalBlockchain)}
      >
        <DialogContainer>
          <CloseButton
            onClick={() => setOpenModalBlockchain(!openModalBlockchain)}
          >
            {IconCloseMS}
          </CloseButton>

          <ContainerCardMedia>
            <img
              // eslint-disable-next-line max-len
              src={constructionData?.blockchain_certificate}
              alt="certificado"
            />
          </ContainerCardMedia>
        </DialogContainer>
      </StyledDialog>
      <StyledDialog
        open={openARTDialog}
        onClose={() => setOpenARTDialog(!openARTDialog)}
      >
        <DialogContainer>
          <CloseButton onClick={() => setOpenARTDialog(!openARTDialog)}>
            {IconCloseMS}
          </CloseButton>
          <ContainerCardMedia>
            <PdfViewer id={reportId} />
          </ContainerCardMedia>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
