import { useState } from 'react';

import {
  AlertTypography,
  ButtonBox,
  DialogContainer,
  FilledButton,
  OutlinedButton,
  StyledDialog,
  TextTypography,
} from './styles';

interface ConfirmationDialogProps {
  title: string;
  text: string;
  modalCallback: () => void;
}

export default function ConfirmationDialog({
  title,
  text,
  modalCallback,
}: ConfirmationDialogProps): JSX.Element {
  const [open, setOpen] = useState(false);

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  const handleConfirm = (): void => {
    modalCallback();
    handleClose();
  };

  return (
    <>
      <FilledButton
        disableTouchRipple
        onClick={handleOpen}
        sx={{ maxWidth: '200px' }}
      >
        {title}
      </FilledButton>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="confirmation dialog"
      >
        <DialogContainer>
          <TextTypography>
            Você gostaria de excluir{' '}
            <span style={{ fontWeight: '600' }}>{text}</span>?
          </TextTypography>
          <ButtonBox>
            <OutlinedButton onClick={handleClose}>Não</OutlinedButton>
            <FilledButton onClick={handleConfirm}>Sim</FilledButton>
          </ButtonBox>
          <AlertTypography>
            Atenção! Esta ação não poderá ser desfeita.
          </AlertTypography>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
