import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogTitle,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogButton = styled(ButtonBase)(() => ({
  padding: '0 60px',
  textDecoration: 'underline',
  textTransform: 'lowercase',
  display: 'flex',
  gap: '8px',
  '&:hover': {
    color: '#FFFFFF',
  },
  '&:active': {
    color: '#E89710',
  },
}));

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    backgroundColor: '#313234',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: '600px',
  minHeight: '400px',
  padding: '40px',
}));

export const CloseButton = styled(ButtonBase)(() => ({
  color: '#707070',
  float: 'right',
  '&:hover': {
    color: '#EDEFF2',
  },
  '&:active': {
    color: '#E89710',
  },
  '& span': { fontSize: '28px', fontWeight: '600' },
}));

export const StyledTitle = styled(DialogTitle)(() => ({
  color: '#EDEFF2',
  fontWeight: '700',
  padding: '16px 0',
  fontSize: '21px',
}));

export const InputBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 40px',
}));

export const InputTitle = styled(Typography)(() => ({
  fontSize: '14px',
  textAlign: 'left',
  color: '#F2F2F2',
  marginBottom: '10px',
  textTransform: 'capitalize',
}));

export const SubmitBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '20px 0',
}));

export const CancelButton = styled(Button)(() => ({
  border: '1.5px solid',
  borderColor: '#E89710',
  width: '190px',
}));

export const SubmitButton = styled(Button)(() => ({
  border: '1.5px solid',
  borderColor: '#E89710',
  backgroundColor: '#E89710',
  textTransform: 'lowercase',
  color: '#EDEFF2',
  width: '190px',
}));
