import { TableBody, TableContainer, TableHead } from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { getInspections } from '../../../api/constructions';
import { InspectionData } from '../../../api/constructions/types';
import { convertReportStatus } from '../../../api/enumerations';
import { BaseUrl, myHeadersWithTokenContentType } from '../../../api/global';
import { IconDescriptionMS } from '../../../constants/icons';
import { Constants } from '../../../constants/reports';
import { GlobalContext } from '../../../context/global';
import {
  checkColor,
  checkStatus,
  formatCellphone,
  formatConclusion,
  getErrorMessage,
} from '../../../helpers';
import { useStoragedJwt } from '../../../hooks/useDecodedJwt';
import { NewReportModal } from '../NewReportModal';
import DownloadDialog from './DownloadDialog';
import ObservationsDialog from './ObservationsDialog';
import {
  CellContent,
  CellHeader,
  DialogDisabled,
  RowContent,
  RowHeader,
  StyledTable,
  StyledTablePagination,
  StyledTooltip,
} from './styles';

export function ReportsTable(): JSX.Element {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [inspectionsData, setInspectionsData] = useState<InspectionData[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage, dashLabel } =
    useContext(GlobalContext);

  const [openModal, setOpenModal] = useState(false);
  const [openObsDialog, setOpenObsDialog] = useState(false);
  const [reportId, setReportId] = useState<number | undefined>();
  const decoded = useStoragedJwt();

  const { id } = useParams();
  const constructionId = parseInt(id as string, 10);

  let URL = `${BaseUrl}/api/V1/constructions/${constructionId}/inspections?`;

  const getDataCallback = useCallback(async () => {
    const currentPage = page + 1;

    try {
      const response = await getInspections(
        constructionId,
        currentPage,
        rowsPerPage
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      if (response.detail.total_records) {
        setTotalRecords(response.detail.total_records);
      }

      if (response.data) {
        setInspectionsData(response.data);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, [
    constructionId,
    page,
    rowsPerPage,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
  ]);

  // eslint-disable-next-line consistent-return
  const getDataWithFilter = useCallback(async () => {
    const len = dashLabel.length;
    if (len === 1) {
      URL += `filter_status=${dashLabel[0]}`;
    } else if (len > 1) {
      for (let i = 0; i < dashLabel.length; i += 1) {
        URL += `&filter_status=${dashLabel[i]}`;
      }
    } else {
      return getDataCallback();
    }

    const requestOptions = {
      method: 'GET',
      headers: myHeadersWithTokenContentType(),
    };
    const response = await fetch(
      `${URL}&page=1&size=${rowsPerPage}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => JSON.parse(result))
      .catch((error) => new Error(error));

    if (response.data) {
      setInspectionsData(response.data);
    }
  }, [dashLabel, getDataCallback]);

  useEffect(() => {
    getDataWithFilter();
    if (dashLabel.length === 0) {
      getDataCallback();
    }
  }, [dashLabel, getDataCallback, getDataWithFilter]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback, page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = (id: number): void => {
    if (decoded?.user.role !== 2) {
      setReportId(id);
      setOpenModal(!openModal);
    }
  };

  const handleCloseModal = (): void => {
    setReportId(undefined);
    setOpenModal(!openModal);
  };

  return (
    <>
      <TableContainer>
        <StyledTable aria-label="tabela">
          <TableHead>
            <RowHeader>
              <CellHeader align="center" bleft>
                {Constants.ref}
              </CellHeader>
              <CellHeader align="center" minwidth="220px">
                {Constants.address}
              </CellHeader>
              <CellHeader align="center" minwidth="220px">
                {Constants.accountable}
              </CellHeader>
              <CellHeader align="center">{Constants.contact}</CellHeader>
              <CellHeader align="center" minwidth="220px">
                {Constants.engineer}
              </CellHeader>
              <CellHeader align="center">{Constants.status}</CellHeader>
              <CellHeader align="center" minwidth="140px">
                {Constants.conclusion}
              </CellHeader>
              <CellHeader align="center">{Constants.download}</CellHeader>
              <CellHeader align="center" bright>
                {Constants.obs}
              </CellHeader>
            </RowHeader>
          </TableHead>
          <TableBody>
            {inspectionsData.map((row) => (
              <RowContent key={row.reference_number}>
                <CellContent
                  align="center"
                  color={checkColor(row.status)}
                  onClick={() => handleOpenModal(row.id)}
                >
                  {row.reference_number &&
                    row.reference_number.toString().padStart(2, '0')}
                </CellContent>
                <CellContent
                  align="center"
                  color={checkColor(row.status)}
                  onClick={() => handleOpenModal(row.id)}
                >
                  {row.address}, {row.number} - {row.complement}
                </CellContent>
                <CellContent
                  align="center"
                  color={checkColor(row.status)}
                  onClick={() => handleOpenModal(row.id)}
                >
                  {row.accountable_person ? row.accountable_person : ' '}
                </CellContent>
                <CellContent
                  align="center"
                  color={checkColor(row.status)}
                  onClick={() => handleOpenModal(row.id)}
                >
                  {row.phone && formatCellphone(row.phone)}
                </CellContent>
                <CellContent
                  align="center"
                  color={checkColor(row.status)}
                  onClick={() => handleOpenModal(row.id)}
                >
                  {row.engineer}
                </CellContent>
                <CellContent
                  align="center"
                  color={checkStatus(row.status)}
                  onClick={() => handleOpenModal(row.id)}
                >
                  {row.status && convertReportStatus(row.status)}
                </CellContent>
                <CellContent
                  align="center"
                  color={checkStatus(row.status)}
                  onClick={() => handleOpenModal(row.id)}
                >
                  {row.finishes_at && formatConclusion(row.finishes_at)}
                </CellContent>
                <CellContent align="center" color="#8B8C8E">
                  <DownloadDialog
                    id={row.id}
                    reference={row.reference_number}
                  />
                </CellContent>
                <CellContent align="center" color="#8B8C8E">
                  {row.observations ? (
                    <ObservationsDialog
                      open={openObsDialog}
                      id={row.id}
                      text={row.observations}
                      reference={row.reference_number}
                    />
                  ) : (
                    <StyledTooltip title={Constants.noObs}>
                      {decoded?.user.role !== 2 ? (
                        <ObservationsDialog
                          open={openObsDialog}
                          id={row.id}
                          text={row.observations}
                          reference={row.reference_number}
                          disabled
                        />
                      ) : (
                        <DialogDisabled>{IconDescriptionMS}</DialogDisabled>
                      )}
                    </StyledTooltip>
                  )}
                </CellContent>
              </RowContent>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <StyledTablePagination
        component="div"
        rowsPerPageOptions={[50, 100, 150]}
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Qtd por página"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
      />
      <NewReportModal
        reportId={reportId}
        open={openModal}
        closeModal={handleCloseModal}
      />
    </>
  );
}
