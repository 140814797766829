import { Box, Button, Dialog, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    backgroundColor: '#313234',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: '600px',
  minHeight: '200px',
  padding: '40px 60px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

export const TextTypography = styled(Typography)(() => ({
  color: '#EDEFF2',
  textAlign: 'center',
  fontSize: '26px',
  fontWeight: '500',
}));

export const AlertTypography = styled(Typography)(() => ({
  color: '#EDEFF2',
  textAlign: 'center',
}));

export const ButtonBox = styled(Box)(() => ({
  margin: '20px 0',
  display: 'flex',
  gap: '40px',
  justifyContent: 'center',
}));

export const OutlinedButton = styled(Button)(() => ({
  border: '1.5px solid',
  borderColor: '#E89710',
  textTransform: 'lowercase',
  maxWidth: '150px',
}));

export const FilledButton = styled(Button)(() => ({
  border: '1.5px solid',
  borderColor: '#E89710',
  backgroundColor: '#E89710',
  textTransform: 'lowercase',
  color: '#EDEFF2',
  maxWidth: '150px',
}));
