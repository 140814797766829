import { Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledGrid = styled(Grid)(() => ({
  padding: '40px 120px 60px 228px',
  // width: '100%',
}));

export const TitleTypography = styled(Typography)(() => ({
  color: '#edeff2',
  fontSize: 21,
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  '& span': {
    color: '#E89710',
    fontSize: 24,
    paddingBottom: 3,
  },
}));

export const SubtitleTypography = styled(Typography)(() => ({
  color: '#EDEFF2',
  fontSize: '18px',
  fontWeight: '600',
  marginTop: '50px',
}));

export const TitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '10px 0',
}));

export const ContainerTable = styled(Box)(() => ({
  width: '100%',
}));
