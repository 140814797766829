import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  tooltipClasses,
  TooltipProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogDisabled = styled(Box)(() => ({
  padding: '0 6px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  gap: '8px',
  cursor: 'default',
  color: '#585c67',
}));

export const DialogButton = styled(ButtonBase)(() => ({
  padding: '0 6px',
  width: '100%',
  display: 'flex',
  gap: '8px',
  color: '#FFFFFF',
  '&:hover': {
    color: '#E89710',
  },
  '&:active': {
    color: '#E89710',
  },
  '&.disabled': {
    '& span': {
      color: '#585c67',
      cursor: 'default',
    },
  },
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    color: '#8B8C8E',
    fontSize: 12,
    fontWeight: '400',
    padding: '2px 4px',
    textTransform: 'lowercase',
    marginTop: '-2px !important',
    '@media (min-width: 1980px)': {
      display: 'none',
    },
  },
}));

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    backgroundColor: '#313234',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minWidth: '600px',
  minHeight: '400px',
  padding: '40px',
}));

export const CloseButton = styled(ButtonBase)(() => ({
  color: '#707070',
  float: 'right',
  '&:hover': {
    color: '#EDEFF2',
  },
  '&:active': {
    color: '#E89710',
  },
  '& span': { fontSize: '28px', fontWeight: '600' },
}));

export const StyledTitle = styled(DialogTitle)(() => ({
  color: '#EDEFF2',
  fontWeight: '700',
  padding: '16px 0',
  fontSize: '21px',
}));

export const RefContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 32,
}));

export const RefNumber = styled(Typography)(() => ({
  color: '#E67715',
  fontWeight: '700',
  padding: '10px 16px',
  fontSize: '18px',
}));

export const Label = styled('label')(() => ({
  border: '1px solid #4E89F6',
  borderRadius: 8,
  padding: 8,
  display: 'flex',
  alignItems: 'center',
  color: '#4E89F6',
  cursor: 'pointer',
  fontWeight: 'bold',
  gap: 6,

  '&:hover': {
    background: '#4E89F6',
    color: '#FFFFFF',
    '& p': {
      color: '#FFF',
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
  },
  '& p': {
    color: '#4E89F6',
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    fontWeight: 'bold',
  },
}));

export const UploadButton = styled('input')(() => ({
  display: 'none',
  width: '100%',
  height: '100%',
}));

export const StyledList = styled(List)(() => ({
  padding: '0',
}));

export const StyledListItem = styled(ListItem)(() => ({
  justifyContent: 'space-between',
  gap: '10px',
  marginBottom: '20px',
  padding: '0 16px',
}));

export const ReportTitle = styled(Typography)(() => ({
  color: '#D7D8DB',
  fontSize: '18px',
  fontWeight: '600',
}));

export const ReportDate = styled(Typography)(() => ({
  color: '#D7D8DB',
  fontSize: '16px',
}));

export const DownloadButton = styled(Button)(() => ({
  width: '194px',
  display: 'flex',
  gap: '10px',
  backgroundColor: '#4E89F6',
  color: '#EDEFF2',
  textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#2981f4',
    color: '#FFFFFF',
  },
  '&:active': {
    backgroundColor: '#4E89F6',
    color: '#FFFFFF',
  },
}));
