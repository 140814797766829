import { Typography } from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  changeLocation,
  deleteLocationImage,
  getConstruction,
} from '../../../api/constructions';
import { IconCloseMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage, validateImg } from '../../../helpers';
import { useStoragedJwt } from '../../../hooks/useDecodedJwt';
import {
  CloseButton,
  DialogContainer,
  OutlinedButton,
  StyledDialog,
  PictureCardMedia,
  FilledButton,
  UploadBox,
  BoxButtons,
  ContainerCardMedia,
} from './styles';

interface SampleDialogProps {
  id: number;
  open: boolean;
  closeModal: () => void;
}

export function SampleDialog({
  id,
  open,
  closeModal,
}: SampleDialogProps): JSX.Element {
  const [currentLocationImg, setCurrentLocationImg] = useState('');
  const [newLocationImg, setNewLocationImg] = useState('');
  const [locationFile, setLocationFile] = useState<FormData>();
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const decoded = useStoragedJwt();

  const handleCloseModal = (): void => {
    setNewLocationImg('');
    closeModal();
  };

  const getConstructionLocation = useCallback(async (): Promise<void> => {
    const response = await getConstruction(id);

    try {
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.data?.location) {
        setCurrentLocationImg(response.data?.location);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, [id, setErrorMessage, setOpenSnackbar, setSnackbarMessage]);

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateImg(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const file = e.target.files[0];
    setNewLocationImg(URL.createObjectURL(file));

    const formData = new FormData();
    formData.append('location', file);
    setLocationFile(formData);
  };

  const handleSubmit = async (): Promise<void> => {
    if (!locationFile) {
      closeModal();
      return;
    }

    try {
      const response = await changeLocation(id, locationFile);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      getConstructionLocation();

      setSnackbarMessage('Upload de imagem realizado com sucesso!');
      setOpenSnackbar(true);
      setErrorMessage(false);
      setTimeout(() => {
        closeModal();
      }, 2000);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
      closeModal();
    }
  };

  const handleDeleteLocationImage = async (): Promise<void> => {
    try {
      const response = await deleteLocationImage(id);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }
      setCurrentLocationImg('');
      setSnackbarMessage('Imagem de localização excluída');
      setOpenSnackbar(true);
      setErrorMessage(false);
      setTimeout(() => {
        closeModal();
        // modalCallback();
      }, 2000);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
      closeModal();
    }
  };

  useEffect(() => {
    getConstructionLocation();
  }, [getConstructionLocation]);

  return (
    <StyledDialog open={open} onClose={handleCloseModal}>
      <DialogContainer>
        <CloseButton onClick={handleCloseModal}>{IconCloseMS}</CloseButton>

        {currentLocationImg || newLocationImg ? (
          <ContainerCardMedia>
            <PictureCardMedia
              src={currentLocationImg || newLocationImg}
              alt="location image"
              sx={{ objectFit: 'contain' }}
            />
          </ContainerCardMedia>
        ) : (
          <UploadBox>
            <label
              htmlFor="uploadLocation"
              style={{ width: '100%', height: '100%' }}
            >
              <Typography>Clique para selecionar uma imagem</Typography>
              <input
                accept="image/*"
                type="file"
                style={{ display: 'none' }}
                id="uploadLocation"
                onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                  handleFileUpload(e)
                }
              />
            </label>
          </UploadBox>
        )}
        {decoded?.user.role !== 2 && (
          <BoxButtons>
            <OutlinedButton onClick={handleDeleteLocationImage}>
              excluir
            </OutlinedButton>
            <FilledButton onClick={handleSubmit}>anexar</FilledButton>
          </BoxButtons>
        )}
      </DialogContainer>
    </StyledDialog>
  );
}
