/* eslint-disable */
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { getAllCompanies } from '../../../api/companies';
import { CompanyProps } from '../../../api/companies/types';
import { NewCompanyModal } from '../../../components/Dialog/NewCompany';
import { Constants } from '../../../constants/companies';
import { IconDeleteMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import { formatCNPJ, formatCellphone, getErrorMessage } from '../../../helpers';
import { CellContent } from '../../Reports/Table/styles';
import {
  CellHeader,
  RowHeader,
  RowContent,
  OutlinedButton,
  ContainerTable,
  OutlinedInputSearch,
  SearchContainer,
  ActionButton,
  StyledTablePagination,
} from './styles';

export function CompaniesTable(): JSX.Element {
  const [companies, setCompanies] = useState<CompanyProps[]>([]);
  const [companyId, setCompanyId] = useState<number | undefined>();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const getCompanyCallback = useCallback(async () => {
    const currentPage = page + 1;

    try {
      const response = await getAllCompanies(currentPage, rowsPerPage, searchTerm);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      if (response.detail.total_records) {
        setTotalRecords(response.detail.total_records);
      }

      if (response.data) {
        setCompanies(response.data);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, [page, rowsPerPage, setErrorMessage, setOpenSnackbar, setSnackbarMessage, searchTerm]);

  const handleUpdateId = (id: number): void => {
    setCompanyId(id);
    setOpen(!open);
  };

  const closeModal = (): void => {
    getCompanyCallback();
    setCompanyId(undefined);
    setOpen(!open);
  };

  useEffect(() => {
    getCompanyCallback();
  }, [getCompanyCallback, page, rowsPerPage, searchTerm]);

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <ContainerTable>
        <SearchContainer>
          <Typography variant="subtitle1">{Constants.panel}</Typography>

          <Box>
            <OutlinedInputSearch
              type="search"
              placeholder="Pesquise na página"
              onChange={(e) => setSearchTerm(e.target.value)}
              startAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
            />
            <OutlinedButton onClick={() => setOpen(!open)}>
              Adicionar empresa
            </OutlinedButton>
          </Box>
        </SearchContainer>
        <TableContainer style={{ width: '100%', marginTop: 12 }}>
          <Table>
            <TableHead>
              <RowHeader>
                <CellHeader align="center" bleft>
                  {Constants.ref}
                </CellHeader>
                <CellHeader align="center">{Constants.name}</CellHeader>
                <CellHeader align="center">{Constants.cnpj}</CellHeader>
                <CellHeader align="center">{Constants.contact}</CellHeader>
                <CellHeader align="center">{Constants.address}</CellHeader>
                <CellHeader align="center">{Constants.responsible}</CellHeader>
                <CellHeader align="center" bright>
                  {Constants.actions}
                </CellHeader>
              </RowHeader>
            </TableHead>
            <TableBody>
              {companies && companies
                .filter((row) => {
                  if (searchTerm === '') {
                    return row;
                  }
                  if (row.name !== null
                    && row.name.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return row;
                  }
                  if (row.cnpj !== null
                    && row.cnpj.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return row;
                  }
                  if (row.contact !== null
                    && row.contact.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return row;
                  }
                  if (row.address !== null
                    && row.address.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return row;
                  }
                  if (row.responsible !== null
                    && row.responsible.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    return row;
                  }
                })
                .map((row) => (
                  <RowContent key={row.id} onClick={() => handleUpdateId(row.id)}>
                    <CellContent align="center" color="#F2F2F2">
                      {row.id &&
                        row.id.toString().padStart(2, '0')}
                    </CellContent>
                    <CellContent align="center" color="#F2F2F2">
                      {row.name}
                    </CellContent>
                    <CellContent align="center" color="#F2F2F2">
                      {row.cnpj && formatCNPJ(row.cnpj)}
                    </CellContent>
                    <CellContent align="center" color="#F2F2F2">
                      {row.contact && formatCellphone(row.contact)}
                    </CellContent>
                    <CellContent align="center" color="#F2F2F2">
                      {`${row.address}, ${row.number} - ${row.complement}`}
                    </CellContent>
                    <CellContent align="center" color="#F2F2F2">
                      {row.responsible}
                    </CellContent>
                    <CellContent
                      align="center"
                      color="#8B8C8E"
                      sx={{ fontWeight: 'bold' }}
                    >
                      <ActionButton onClick={() => handleUpdateId(row.id)}>
                        {IconDeleteMS}
                      </ActionButton>
                    </CellContent>
                  </RowContent>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledTablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 20]}
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Qtd por página"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
        />
      </ContainerTable>
      <NewCompanyModal open={open} closeModal={closeModal} id={companyId} />
    </>
  );
}
