/* eslint-disable react-hooks/exhaustive-deps */
import { FormEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { resetPassword } from '../../../api/login';
import {
  NewPasswordTextField,
  ResetPasswordTextField,
} from '../../../components/CustomInput';
import { IconCloseMS } from '../../../constants/icons';
import { Constants } from '../../../constants/settings';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import {
  CancelButton,
  CloseButton,
  DialogButton,
  DialogContainer,
  InputBox,
  InputTitle,
  StyledDialog,
  StyledTitle,
  SubmitBox,
  SubmitButton,
} from './styles';

export default function ResetPassword(): JSX.Element {
  const [open, setOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const navigate = useNavigate();

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();

    if (newPassword !== confirmation) {
      setSnackbarMessage('As senhas devem ser iguais');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const data = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    try {
      const response = await resetPassword(data);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      setSnackbarMessage('Senha atualizada! Você será redirecionado ao login.');
      setOpenSnackbar(true);
      setErrorMessage(false);
      setTimeout(() => {
        localStorage.removeItem('precautionary-token');
        navigate('/login');
      }, 2000);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  return (
    <>
      <DialogButton disableTouchRipple onClick={handleOpen}>
        {Constants.changePassword}
      </DialogButton>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby={Constants.changePassword}
      >
        <DialogContainer>
          <CloseButton disableTouchRipple onClick={handleClose}>
            {IconCloseMS}
          </CloseButton>
          <StyledTitle>{Constants.changePassword}</StyledTitle>
          <InputBox component="form" id="password" onSubmit={handleSubmit}>
            <InputTitle>{Constants.currentPassword}</InputTitle>
            <ResetPasswordTextField
              id="senha-atual"
              value={oldPassword}
              setValue={setOldPassword}
              textType={showOldPassword}
              setTextType={setShowOldPassword}
            />
            <InputTitle>{Constants.newPassword}</InputTitle>
            <NewPasswordTextField
              id="nova-senha"
              value={newPassword}
              setValue={setNewPassword}
              textType={showNewPassword}
              setTextType={setShowNewPassword}
              valueError={newPasswordError}
              setValueError={setNewPasswordError}
            />
            <InputTitle>{Constants.confirmPassword}</InputTitle>
            <ResetPasswordTextField
              id="confirmar-senha"
              value={confirmation}
              setValue={setConfirmation}
              textType={showConfirmation}
              setTextType={setShowConfirmation}
            />
            <SubmitBox>
              <CancelButton onClick={handleClose}>
                {Constants.cancel}
              </CancelButton>
              <SubmitButton
                form="password"
                type="submit"
                disabled={newPasswordError}
              >
                {Constants.changePassword}
              </SubmitButton>
            </SubmitBox>
          </InputBox>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
