export const IconArrowBackMS = (
  <span className="material-symbols-outlined">arrow_back_ios_new</span>
);

export const IconArrowForwardMS = (
  <span className="material-symbols-outlined">arrow_forward_ios</span>
);

export const IconCloseMS = (
  <span className="material-symbols-outlined">close</span>
);

export const IconDescriptionMS = (
  <span className="material-symbols-outlined">description</span>
);

export const IconDownloadMS = (
  <span className="material-symbols-outlined">download</span>
);

export const IconEditMS = (
  <span className="material-symbols-outlined">edit</span>
);

export const IconHomeMS = (
  <span className="material-symbols-outlined">home</span>
);

export const IconKeyMS = <span className="material-symbols-outlined">key</span>;

export const IconLogoutMS = (
  <span className="material-symbols-outlined">logout</span>
);

export const IconMemoryMS = (
  <span className="material-symbols-outlined">memory</span>
);

export const IconPersonMS = (
  <span className="material-symbols-outlined">person</span>
);

export const IconPieChartMS = (
  <span className="material-symbols-outlined">pie_chart</span>
);

export const IconSettingsMS = (
  <span className="material-symbols-outlined">settings</span>
);

export const IconSquareFootMS = (
  <span className="material-symbols-outlined">square_foot</span>
);

export const IconVisibilityMS = (
  <span className="material-symbols-outlined">visibility</span>
);

export const IconVisibilityOffMS = (
  <span className="material-symbols-outlined">visibility_off</span>
);

export const IconLocationOnMS = (
  <span className="material-symbols-outlined">location_on</span>
);

export const IconAppartment = (
  <span className="material-symbols-outlined">apartment</span>
);

export const IconDeleteMS = (
  <span className="material-symbols-outlined">delete</span>
);

export const IconSearchMS = (
  <span className="material-symbols-outlined">search</span>
);

export const IconCalendarMS = (
  <span className="material-symbols-outlined">calendar_today</span>
);

export const IconExpandMoreMS = (
  <span className="material-symbols-outlined">expand_more</span>
);

export const IconPublishMS = (
  <span className="material-symbols-outlined">publish</span>
);

export const IconTableViewMS = (
  <span className="material-symbols-outlined">table_view</span>
);
