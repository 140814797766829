import { Snackbar as MuiSnackbar, Typography } from '@mui/material';
import { useContext } from 'react';

import { GlobalContext } from '../../context/global';

export default function Snackbar(): JSX.Element {
  const { openSnackbar, setOpenSnackbar, errorMessage, snackbarMessage } =
    useContext(GlobalContext);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const sbErrorMessage = (
    <Typography
      sx={{
        display: 'flex',
        gap: '4px',
        color: '#FAFCFF',
        '& span': { color: '#ff0f0f' },
      }}
    >
      <span className="material-symbols-outlined">error</span>
      {snackbarMessage}
    </Typography>
  );

  const successMessage = (
    <Typography
      sx={{
        display: 'flex',
        gap: '4px',
        color: '#FAFCFF',
        '& span': { color: '#62aa41' },
      }}
    >
      <span className="material-symbols-outlined">check_circle</span>
      {snackbarMessage}
    </Typography>
  );

  return (
    <MuiSnackbar
      open={openSnackbar}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleClose}
      message={errorMessage ? sbErrorMessage : successMessage}
      sx={{ '.MuiSnackbarContent-root': { minWidth: 'fit-content' } }}
    />
  );
}
