/* eslint-disable no-console */
import { FormEvent, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { login } from '../../api/login';
import logo from '../../assets/images/logo-realprice.png';
import {
  EmailIcon,
  IconVisibility,
  PasswordIcon,
} from '../../components/InputIcon';
import Snackbar from '../../components/Snackbar';
import { Constants } from '../../constants/login';
import { GlobalContext } from '../../context/global';
import { validateEmail, validatePassword } from '../../helpers';
import {
  ContainerGrid,
  InputBox,
  InputTitle,
  LoginGrid,
  LogoGrid,
  LogoImg,
  StyledButtonBase,
  StyledTextField,
  SubmitButton,
} from './styles';

export function Login(): JSX.Element {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const navigate = useNavigate();
  const token = localStorage.getItem('precautionary-token');

  useEffect(() => {
    if (token !== null && token !== 'undefined') {
      navigate('/home');
    }
  }, [navigate, token]);

  const handleSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    const trimEmail = email.trim();

    try {
      const response = await login(trimEmail, password);

      if (response.detail.status_code === 3) {
        setSnackbarMessage('Email ou senha inválidos');
        setErrorMessage(true);
        setOpenSnackbar(true);
        return;
      }

      if (response.detail.status_code === 0 && response.access_token) {
        const item = response.access_token;
        localStorage.setItem('precautionary-token', item);
        sessionStorage.setItem('company', '0');
        navigate('/home');
      }
    } catch (error) {
      setSnackbarMessage('Algo deu errado, tente novamente');
      setErrorMessage(true);
      setOpenSnackbar(true);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  };

  return (
    <ContainerGrid container>
      <LogoGrid item xs={8}>
        <LogoImg image={logo} title="logo" />
      </LogoGrid>
      <LoginGrid item xs={4}>
        <InputBox component="form" id="login" onSubmit={handleSubmit}>
          <InputTitle>{Constants.title}</InputTitle>
          <StyledTextField
            required
            id="email"
            variant="standard"
            InputProps={EmailIcon}
            placeholder={Constants.emailPlaceholder}
            error={emailError}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              !validateEmail(e.target.value)
                ? setEmailError(true)
                : setEmailError(false);
            }}
          />
          <StyledTextField
            required
            type={showPassword ? 'text' : 'password'}
            variant="standard"
            InputProps={{
              startAdornment: <PasswordIcon />,
              endAdornment: (
                <IconVisibility
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                />
              ),
            }}
            id="password"
            placeholder={Constants.passwordPlaceholder}
            error={passwordError}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              !validatePassword(e.target.value)
                ? setPasswordError(true)
                : setPasswordError(false);
            }}
          />
          <SubmitButton
            form="login"
            type="submit"
            disabled={emailError || passwordError}
          >
            {Constants.submit}
          </SubmitButton>
          <a
            target="blank"
            href="mailto:teste@teste.com"
            style={{ color: '#8B8C8E' }}
          >
            <StyledButtonBase disableTouchRipple>
              {Constants.forgotPassword}
            </StyledButtonBase>
          </a>
          <p>
            Primeiro acesso?{' '}
            <a href="/first-access" style={{ color: '#8B8C8E' }}>
              <StyledButtonBase>Clique aqui!</StyledButtonBase>
            </a>
          </p>
        </InputBox>
      </LoginGrid>
      {openSnackbar && <Snackbar />}
    </ContainerGrid>
  );
}
