/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import { useCallback, useContext, useState } from 'react';

import { editInspectionObs } from '../../../../api/inspections';
import {
  IconCloseMS,
  IconDescriptionMS,
  IconEditMS,
} from '../../../../constants/icons';
import { Constants } from '../../../../constants/reports';
import { GlobalContext } from '../../../../context/global';
import { getErrorMessage } from '../../../../helpers';
import { useStoragedJwt } from '../../../../hooks/useDecodedJwt';
import {
  CancelButton,
  CloseButton,
  ContainerActionButtons,
  DialogButton,
  DialogContainer,
  EditButton,
  RefNumber,
  SaveButton,
  StyledDialog,
  StyledTitle,
  StyledTooltip,
  TextArea,
  TextTypography,
} from './styles';

interface DownloadDialogProps {
  id: number;
  text: string;
  reference: number;
  open: boolean;
  disabled?: boolean;
}

export default function ObservationsDialog({
  id,
  text,
  reference,
  open,
  disabled,
}: DownloadDialogProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(open);
  const [editing, setEditing] = useState(false);
  const [obsText, setObsText] = useState(text);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const decoded = useStoragedJwt();

  const handleCancelButton = (): void => {
    setObsText(text);
    setEditing(!editing);
  };

  const handleOpen = (): void => setIsOpen(true);
  const handleClose = (): void => setIsOpen(false);

  const handleEditObservation = useCallback(async () => {
    if (obsText !== text) {
      try {
        const response = await editInspectionObs(id, obsText);

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente!');
        }

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        setSnackbarMessage('Sucesso!');
        setOpenSnackbar(true);
        setErrorMessage(false);
        setEditing(!editing);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }
  }, [
    editing,
    id,
    obsText,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    text,
  ]);

  return (
    <>
      <StyledTooltip title={Constants.observations}>
        <DialogButton
          disableTouchRipple
          onClick={handleOpen}
          className={disabled ? 'disabled' : ''}
        >
          {IconDescriptionMS}
        </DialogButton>
      </StyledTooltip>
      <StyledDialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="observações"
      >
        <DialogContainer>
          <CloseButton disableTouchRipple onClick={handleClose}>
            {IconCloseMS}
          </CloseButton>
          <StyledTitle>{Constants.observations}</StyledTitle>
          <RefNumber>
            {Constants.refDialog}
            {reference}
            {decoded?.user.role !== 2 && (
              <EditButton
                style={{ display: editing ? 'none' : 'inline' }}
                onClick={() => setEditing(!editing)}
              >
                {IconEditMS}
              </EditButton>
            )}
          </RefNumber>
          {!editing ? (
            obsText === text ? (
              <TextTypography>{text}</TextTypography>
            ) : (
              <TextTypography>{obsText}</TextTypography>
            )
          ) : (
            <TextArea
              value={obsText}
              onChange={(e) => setObsText(e.target.value)}
            />
          )}
          <ContainerActionButtons>
            <div />
            <div
              style={{
                display: !editing ? 'none' : 'flex',
                gap: 12,
                justifyContent: 'space-between',
              }}
            >
              <CancelButton onClick={handleCancelButton}>cancelar</CancelButton>
              <SaveButton onClick={handleEditObservation}>salvar</SaveButton>
            </div>
          </ContainerActionButtons>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
