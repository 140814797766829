import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ChartPaper = styled(Paper)(({ theme }) => ({
  padding: '32px 0',
  backgroundColor: '#0A1123',
  border: '1px solid #2B3550',
  borderRadius: '10px',
  // minHeight: '531px',
  width: 500,
  [theme.breakpoints.up('lg')]: {
    width: 620,
    height: 550,
  },
}));

export const ChartTitle = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600',
  color: '#EDEFF2',
  borderBottom: '1px solid #378EF2',
  width: 'max-content',
  marginBottom: '20px',
}));

export const ChartsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '20px',
  justifyContent: 'space-around',
  marginTop: '40px',
  width: 600,
}));

export const ChartBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  // margin: '0 42px',
  maxWidth: '170px',
  [theme.breakpoints.up('lg')]: {
    height: 200,
  },
}));

export const InnerBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ChartValue = styled(Typography)(() => ({
  position: 'absolute',
  fontSize: 16,
  fontWeight: '600',
  color: '#ECF0F6',
}));

export const BackProgress = styled(CircularProgress)(() => ({
  position: 'absolute',
  color: '#378EF280',
}));

export const FrontProgress = styled(CircularProgress)(() => ({
  color: '#E89710',
}));

export const PercentChartLabel = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontSize: '16px',
  color: '#E89710',
  padding: '10px 0',
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '140px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '160px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '180px',
  },
}));

export const DashboardPaper = styled(Paper)(() => ({
  padding: '40px',
  backgroundColor: '#0A1123',
  border: '1px solid #2B3550',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 32,
  '& .active': {
    backgroundColor: '#E89710',
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    padding: '1px 4px',
  },
}));

export const DashboardBox = styled(Box)(() => ({
  width: '35%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 4,
  cursor: 'pointer',
}));

export const TotalReports = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600',
  color: '#EDEFF2',
}));

export const TotalReportsValue = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: '600',
  color: '#EDEFF2',
  backgroundColor: '#378EF2',
  padding: '4px 20px 2px',
  borderRadius: '25px',
  textAlign: 'center',
  minWidth: '70px',
}));

export const ReportsTypography = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '400',
  color: '#EDEFF2',
  cursor: 'pointer',
}));

export const ReportsValue = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: '600',
  color: '#EDEFF2',
  backgroundColor: '#E89710',
  padding: '4px 20px 2px',
  borderRadius: '25px',
  textAlign: 'center',
  minWidth: '70px',
}));
