/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigate } from 'react-router-dom';

import { useStoragedJwt } from './hooks/useDecodedJwt';

export default function PrivateRoute({
  children,
  redirectTo,
}: {
  children: React.ReactNode;
  redirectTo: string;
}): any {
  try {
    const decoded = useStoragedJwt();

    if (decoded === null || decoded.expired) {
      localStorage.removeItem('precautionary-token');
      return <Navigate to={redirectTo} />;
    }
    return children;
  } catch {
    return <Navigate to={redirectTo} />;
  }
}
