import {
  Box,
  Button,
  ButtonBase,
  CardMedia,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import backgroundImageLogin from '../../assets/images/background-realprice.png';

export const ContainerGrid = styled(Grid)(() => ({
  backgroundImage: `url(${backgroundImageLogin})`,
  backgroundSize: '1980px 1080px',
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  minHeight: '800px',
  height: '100vh',
  justifyContent: 'center',
}));

export const LogoGrid = styled(Grid)(() => ({
  display: 'flex',
  maxHeight: '1080px',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const LogoImg = styled(CardMedia)(({ theme }) => ({
  zIndex: 5,
  [theme.breakpoints.down('lg')]: {
    width: '460px',
    height: '180px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '610px',
    height: '240px',
  },
}));

export const LoginGrid = styled(Grid)(() => ({
  display: 'flex',
  maxHeight: '1080px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#030B1DB3',
}));

export const InputBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '& p': {
    marginTop: 116,
  },
}));

export const InputTitle = styled(Typography)(() => ({
  fontSize: '37px',
  marginBottom: '140px',
  color: '#FAFCFF',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: '40px',
  [theme.breakpoints.down('md')]: {
    width: '280px',
  },
  [theme.breakpoints.up('md')]: {
    width: '320px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '500px',
  },
  '& .MuiInputBase-input': {
    color: '#FFF',
    fontSize: '18px',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:before': {
    borderBottom: '1px solid #8B8C8E',
  },
  '& .MuiInputBase-root.MuiInput-root:hover:after': {
    borderBottom: '1px solid #8B8C8E',
  },
  'input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0px 1000px #030B1D inset',
    paddingLeft: '10px',
    WebkitTextFillColor: '#FFFFFF',
  },
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  margin: '40px 0',
  border: '1.5px solid',
  borderColor: '#E89710',
  [theme.breakpoints.down('md')]: {
    width: '280px',
  },
  [theme.breakpoints.up('md')]: {
    width: '320px',
  },
  [theme.breakpoints.up('xl')]: {
    width: '500px',
  },
}));

export const StyledButtonBase = styled(ButtonBase)(() => ({
  textDecoration: 'underline',
  fontSize: '18px',
  '&:hover': {
    fontWeight: '600',
  },
}));
