import { Dialog, Box, ButtonBase, Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    minWidth: '65vw',
    // height: '100vh',
    borderRadius: 16,
    backgroundColor: '#313234',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minHeight: 400,
  padding: 40,
}));

export const CloseButton = styled(ButtonBase)(() => ({
  color: '#707070',
  float: 'right',
  '&:hover': {
    color: '#edeff2',
  },
  '& span': {
    fontSize: 28,
    fontWeight: 600,
  },
}));

export const ContainerCardMedia = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: '16px auto',
}));

export const PictureCardMedia = styled('img')(() => ({
  width: '80vw',
  height: 570,
  borderRadius: '10px',
  objectFit: 'initial',
}));

export const ButtonGrid = styled(Grid)(() => ({
  justifyContent: 'space-between',
  padding: 20,
}));

export const UploadBox = styled(Box)(() => ({
  width: '95%',
  margin: '32px auto',
  border: `1px solid #E89710`,
  borderRadius: 12,
  height: 200,

  '& label': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      fontSize: 20,
    },
  },
}));

export const BoxButtons = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'end',
  justifyContent: 'flex-end',
  gap: 24,
  bottom: 10,
  width: '100%',
}));

export const OutlinedButton = styled(Button)(() => ({
  border: '1px solid #E89710',
  textTransform: 'lowercase',
  borderRadius: 4,
  width: 200,
}));

export const FilledButton = styled(Button)(() => ({
  backgroundColor: '#E89710',
  color: '#EDEFF2',
  textTransform: 'lowercase',
  borderRadius: 4,
  width: 300,
}));
