import Menu from '../../components/Menu';
import { Constants } from '../../constants/companies';
import { IconAppartment } from '../../constants/icons';
import { StyledGrid, TitleBox, TitleTypography } from './styles';
import { CompaniesTable } from './Table/Table';

export function Companies(): JSX.Element {
  return (
    <>
      <Menu />
      <StyledGrid>
        <TitleTypography>
          {IconAppartment}
          {Constants.companies}
        </TitleTypography>

        <TitleBox />
        <CompaniesTable />
      </StyledGrid>
    </>
  );
}
