import {
  Box,
  TableRow,
  TableCell,
  Button,
  OutlinedInput,
  ButtonBase,
  TablePagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TableCellProps } from '@mui/material/TableCell';
import { Component } from 'react';

import { theme } from '../../../theme';

export const ContainerTable = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: 128,
}));

export const RowHeader = styled(TableRow)(() => ({
  backgroundColor: '#0A1123',
  borderTopRightRadius: 10,
}));

export const RowContent = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#171D2D',
  },

  '&:hover': {
    backgroundColor: '#19305E',
    cursor: 'pointer',
  },
}));

interface CellHeaderProps extends TableCellProps {
  minwidth?: string;
  bleft?: boolean;
  bright?: boolean;
}

export const CellHeader = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'bleft' && prop !== 'bright',
})<CellHeaderProps>(({ minwidth, bleft, bright }) => ({
  border: '1px solid #2B3550',
  fontSize: 16,
  fontWeight: 600,
  color: '#F2F2F2',
  minwidth,
  ...(bleft && {
    borderTopLeftRadius: 10,
  }),
  ...(bright && {
    borderTopRightRadius: 10,
  }),
}));

export const SearchContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& h6': {
    fontWeight: 'bold',
    fontSize: 18,
    color: '#EDEFF2',
  },

  '& div': {
    display: 'flex',
    gap: 100,
  },
}));

export const OutlinedInputSearch = styled(OutlinedInput)(() => ({
  borderRadius: 24,
  width: 380,
  height: 50,
  color: '#f2f2f2',
  border: '1px solid #FAFCFF',
  padding: 0,

  '& svg': {
    color: '#f2f2f2',
  },
}));

export const OutlinedButton = styled(Button)(() => ({
  border: '1.5px solid #E89710',
  color: '#fff',
  borderRadius: 4,
  width: 250,
  float: 'right',
}));

export const ActionButton = styled(ButtonBase)(() => ({
  '&:hover': {
    color: theme.palette.secondary.light,
    fontWeight: 'bold',
  },
  '& svg': {
    fontWeight: 700,
  },
}));

type StyledTablePaginationProps = {
  component: string;
};

export const StyledTablePagination = styled(
  TablePagination
)<StyledTablePaginationProps>(() => ({
  color: '#F2F2F2',
  marginBottom: '10px',
  component: Component,
  '& .MuiTablePagination-selectLabel': {
    color: '#F2F2F2',
  },
  '& .MuiInputBase-root': {
    '& .MuiSvgIcon-root': {
      color: '#F2F2F2',
    },
  },
  '& .MuiTablePagination-displayedRows': {
    color: '#F2F2F2',
  },
  '& .MuiButtonBase-root': {
    backgroundColor: '#313234',
    color: '#F2F2F2',
    margin: '0 8px',
    '&.Mui-disabled': {
      backgroundColor: '#0A1123',
      color: '#8B8C8E',
    },
    '&:hover': {
      color: '#E89710',
      backgroundColor: '#313234',
    },
    '& .MuiSvgIcon-root': {
      '&:hover': {
        color: '#E89710',
      },
    },
  },
}));
