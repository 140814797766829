/* eslint-disable react/jsx-no-constructed-context-values */
import { Dispatch, SetStateAction, createContext, useState } from 'react';

interface GlobalProviderProps {
  openSnackbar: boolean;
  setOpenSnackbar: (isSnackbarOpen: boolean) => void;
  errorMessage: boolean;
  setErrorMessage: (errorMessage: boolean) => void;
  snackbarMessage: string;
  setSnackbarMessage: (message: string) => void;
  navbarLogo: string;
  setNavbarLogo: (logo: string) => void;
  dashLabel: string[];
  setDashLabel: Dispatch<SetStateAction<string[]>>;
}

export const GlobalContext = createContext({} as GlobalProviderProps);

type MyComponentProps = React.PropsWithChildren;

export function GlobalProvider({ children }: MyComponentProps): JSX.Element {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [navbarLogo, setNavbarLogo] = useState('');
  const [dashLabel, setDashLabel] = useState<string[]>([]);

  return (
    <GlobalContext.Provider
      value={{
        openSnackbar,
        setOpenSnackbar,
        errorMessage,
        setErrorMessage,
        snackbarMessage,
        setSnackbarMessage,
        navbarLogo,
        setNavbarLogo,
        dashLabel,
        setDashLabel,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
