import { DetailResponse } from '../constructions/types';
import {
  BaseUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../global';
import {
  ChangeCompanyProps,
  ChangeLogoProps,
  GetAllCompanyProps,
  GetCompanyProps,
  NewCompanyProps,
} from './types';

export const getCompany = (id: number): Promise<GetCompanyProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/companies/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getAllCompanies = (
  page: number,
  rows: number,
  searchString?: string
): Promise<GetAllCompanyProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/companies/search?search_string=${searchString}&page=${page}&size=${rows}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const addNewCompany = (
  name: string,
  responsible: string,
  contact: string,
  address: string,
  number: string,
  complement: string,
  cnpj: string
): Promise<NewCompanyProps> => {
  const jsonBody = JSON.stringify({
    name,
    responsible,
    contact,
    address,
    number,
    complement,
    cnpj,
  });
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: jsonBody,
  };
  return fetch(`${BaseUrl}/api/V1/companies`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const changeCompany = (
  id: number,
  name: string,
  responsible: string,
  contact: string,
  address: string,
  number: string,
  complement: string,
  cnpj: string
): Promise<ChangeCompanyProps> => {
  const jsonBody = JSON.stringify({
    name,
    responsible,
    contact,
    address,
    number,
    complement,
    cnpj,
  });
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: jsonBody,
  };
  return fetch(`${BaseUrl}/api/V1/companies/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const deleteCompany = (id: number): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/companies/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const changeLogo = (
  id: number,
  data: FormData
): Promise<ChangeLogoProps> => {
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithToken(),
    body: data,
  };
  return fetch(`${BaseUrl}/api/V1/companies/${id}/logo`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
