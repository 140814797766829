import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { resetPasswordWithToken } from '../../../api/login';
import { PasswordIcon, IconVisibility } from '../../../components/InputIcon';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage, validatePassword } from '../../../helpers';
import {
  ButtonBox,
  FilledButton,
  OutlinedButton,
  SectionSubtitle,
  SectionTitle,
  StyledDialog,
  StyledTextField,
} from './styles';

interface ChangePasswordProps {
  open: boolean;
  closeModal: () => void;
  tempToken: string;
}

export function ChangePassword({
  open,
  closeModal,
  tempToken,
}: ChangePasswordProps): JSX.Element {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [confirmation, setConfirmation] = useState('');
  const [confirmationError, setConfirmationError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const navigate = useNavigate();

  const handleSubmitPassword = async (): Promise<void> => {
    const fd = new FormData();
    fd.append('password', password);
    try {
      const response = await resetPasswordWithToken(tempToken, fd);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      setSnackbarMessage('Senha salva com sucesso');
      setOpenSnackbar(true);
      setErrorMessage(false);
      closeModal();

      setTimeout(() => {
        navigate('/login');
      }, 1500);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  return (
    <StyledDialog open={open}>
      <SectionTitle>Nova senha</SectionTitle>
      <SectionSubtitle>Digite uma senha</SectionSubtitle>
      <StyledTextField
        required
        type={showPassword ? 'text' : 'password'}
        variant="standard"
        helperText={
          password &&
          'mínimo de 8 caracteres com letra mínuscula, maiúscula e número'
        }
        style={{ color: '#fff' }}
        InputProps={{
          startAdornment: <PasswordIcon />,
          endAdornment: (
            <IconVisibility
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
          ),
        }}
        id="password"
        placeholder="digite sua senha"
        error={passwordError}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          !validatePassword(e.target.value)
            ? setPasswordError(true)
            : setPasswordError(false);
        }}
      />
      <StyledTextField
        required
        type={showConfirmation ? 'text' : 'password'}
        variant="standard"
        helperText={confirmation && 'as senhas devem ser iguais'}
        InputProps={{
          startAdornment: <PasswordIcon />,
          endAdornment: (
            <IconVisibility
              showPassword={showConfirmation}
              setShowPassword={setShowConfirmation}
            />
          ),
        }}
        id="confirmation"
        placeholder="confirme sua senha"
        error={confirmationError}
        value={confirmation}
        onChange={(e) => {
          setConfirmation(e.target.value);
          e.target.value !== password
            ? setConfirmationError(true)
            : setConfirmationError(false);
        }}
      />
      <ButtonBox>
        <OutlinedButton onClick={closeModal}>cancelar</OutlinedButton>
        <FilledButton
          onClick={handleSubmitPassword}
          disabled={passwordError || confirmationError}
        >
          salvar
        </FilledButton>
      </ButtonBox>
    </StyledDialog>
  );
}
