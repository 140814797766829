/* eslint-disable consistent-return */
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { getAllCompanies } from '../../../api/companies';
import { CompanyProps } from '../../../api/companies/types';
import { getAllUsers } from '../../../api/users';
import { UserData } from '../../../api/users/types';
import { NewUser } from '../../../components/Dialog/NewUser';
import { IconDeleteMS } from '../../../constants/icons';
import { Constants } from '../../../constants/users';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import { CellContent } from '../../Reports/Table/styles';
import {
  ContainerTable,
  ActionButton,
  CellHeader,
  OutlinedButton,
  OutlinedInputSearch,
  RowContent,
  RowHeader,
  SearchContainer,
  StyledTablePagination,
} from './styles';

export function UserTable(): JSX.Element {
  const [users, setUsers] = useState<UserData[]>([]);
  const [companies, setCompanies] = useState<CompanyProps[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowCompanyId, setRowCompanyId] = useState<number | undefined>();
  const [searchTerm, setSearchTerm] = useState('');

  const [openModal, setOpenModal] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const getUsers = useCallback(async () => {
    const currentPage = page + 1;

    try {
      const response = await getAllUsers(currentPage, rowsPerPage, searchTerm);

      if (response.detail.description) {
        throw new Error('Algo deu errado, tente novamente');
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      if (response.detail.total_records) {
        setTotalRecords(response.detail.total_records);
      }

      if (response.data) {
        setUsers(response.data);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, [
    page,
    rowsPerPage,
    searchTerm,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
  ]);

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function getCompanies() {
    const response = await getAllCompanies(1, 50, '');
    if (response.data) {
      setCompanies(response.data);
    }
  }

  const handleUpdateRowCompanyId = (id: number): void => {
    setRowCompanyId(id);
    setOpenModal(!openModal);
  };

  const closeModal = (): void => {
    setRowCompanyId(undefined);
    setOpenModal(!openModal);
  };

  useEffect(() => {
    getCompanies();
    getUsers();
  }, [getUsers, page, rowsPerPage]);

  return (
    <>
      <ContainerTable>
        <SearchContainer>
          <Typography variant="subtitle1">{Constants.panel}</Typography>

          <Box>
            <OutlinedInputSearch
              type="search"
              placeholder="Pesquise na página"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              startAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
            />
            <OutlinedButton onClick={() => setOpenModal(!openModal)}>
              Adicionar usuário
            </OutlinedButton>
          </Box>
        </SearchContainer>
        <TableContainer style={{ width: '100%', marginTop: 12 }}>
          <Table>
            <TableHead>
              <RowHeader>
                <CellHeader align="center" bleft>
                  {Constants.ref}
                </CellHeader>
                <CellHeader align="center">{Constants.companies}</CellHeader>
                <CellHeader align="center">{Constants.name}</CellHeader>
                <CellHeader align="center">{Constants.email}</CellHeader>
                <CellHeader align="center" bright>
                  {Constants.actions}
                </CellHeader>
              </RowHeader>
            </TableHead>
            <TableBody>
              {users &&
                users
                  // eslint-disable-next-line array-callback-return
                  .filter((row) => {
                    if (searchTerm === '') {
                      return row;
                    }
                    if (
                      row.name !== null &&
                      row.name.toLowerCase().includes(searchTerm.toLowerCase())
                    ) {
                      return row;
                    }
                    if (
                      row.company.name !== null &&
                      row.company.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return row;
                    }
                    if (
                      row.email !== null &&
                      row.email.toLowerCase().includes(searchTerm.toLowerCase())
                    ) {
                      return row;
                    }
                  })
                  .map((row) => (
                    <RowContent
                      onClick={() => handleUpdateRowCompanyId(row.id)}
                      key={row.id}
                    >
                      <CellContent align="center" color="#f2f2f2">
                        {row.id && row.id.toString().padStart(2, '0')}
                      </CellContent>
                      <CellContent align="center" color="#f2f2f2">
                        {row.company.name}
                      </CellContent>
                      <CellContent align="center" color="#f2f2f2">
                        {row.name}
                      </CellContent>
                      <CellContent align="center" color="#f2f2f2">
                        {row.email}
                      </CellContent>
                      <CellContent align="center" color="#f2f2f2">
                        <ActionButton>{IconDeleteMS}</ActionButton>
                      </CellContent>
                    </RowContent>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <StyledTablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 20]}
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Qtd por página"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
        />
      </ContainerTable>
      <NewUser
        open={openModal}
        closeModal={closeModal}
        companies={companies}
        id={rowCompanyId}
      />
    </>
  );
}
