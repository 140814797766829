import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import { useStoragedJwt } from './hooks/useDecodedJwt';
import { Companies } from './pages/Companies';
import { FirstAccess } from './pages/FistAccess';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { Reports } from './pages/Reports';
import { Settings } from './pages/Settings';
import { Users } from './pages/Users';
import PrivateRoute from './PrivateRoute';
import { theme } from './theme';

export default function App(): JSX.Element {
  const decoded = useStoragedJwt();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/first-access" element={<FirstAccess />} />
          <Route
            path="/home"
            element={
              <PrivateRoute redirectTo="/login">
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports/:id"
            element={
              <PrivateRoute redirectTo="/login">
                <Reports />
              </PrivateRoute>
            }
          />
          {decoded?.user.role !== 2 ? (
            <>
              <Route
                path="/companies"
                element={
                  <PrivateRoute redirectTo="/login">
                    <Companies />
                  </PrivateRoute>
                }
              />
              <Route
                path="/users"
                element={
                  <PrivateRoute redirectTo="/login">
                    <Users />
                  </PrivateRoute>
                }
              />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/home" replace />} />
          )}
          <Route
            path="/settings"
            element={
              <PrivateRoute redirectTo="/login">
                <Settings />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
