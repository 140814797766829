import { Box, ButtonBase, CardMedia, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledGrid = styled(Grid)(() => ({
  padding: '40px 120px 60px 228px ',
}));

export const TitleTypography = styled(Typography)(() => ({
  color: '#EDEFF2',
  fontSize: '21px',
  fontWeight: '600',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '40px',
  '& span': {
    color: '#E89710',
    fontSize: '24px',
    paddingBottom: '3px',
  },
}));

export const ConstructionName = styled(Typography)(() => ({
  color: '#EDEFF2',
  fontSize: '21px',
  fontWeight: '600',
  textTransform: 'uppercase',
}));

export const SubtitleTypography = styled(Typography)(() => ({
  color: '#EDEFF2',
  fontSize: '18px',
  fontWeight: '600',
  marginBottom: '4px',
  borderBottom: '1px solid #378EF2',
}));

export const ConclusionTypography = styled(Typography)(() => ({
  color: '#EDEFF2',
  fontSize: '18px',
  fontWeight: '600',
  marginBottom: '4px',
}));

export const DateTypography = styled(Typography)(() => ({
  color: '#378EF2',
  fontSize: '16px',
  fontWeight: '400',
  marginBottom: '20px',
}));

export const TitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '10px 0',
}));

export const InfoBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
}));

export const DownloadButton = styled(ButtonBase)(() => ({
  padding: '0 6px',
  display: 'flex',
  gap: '8px',
  '&:hover': {
    color: '#FFFFFF',
    cursor: 'pointer',
  },
  '&:active': {
    color: '#E89710',
  },
}));

export const LocationButton = styled(ButtonBase)(() => ({
  color: '#E89710',
  display: 'flex',
  gap: 4,
  fontFamily: 'Montserrat',
  fontSize: 20,
  '&:hover': {
    color: '#FFFFFF',
  },
}));

export const ContainerButtons = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: 32,
  marginTop: 16,
  marginBottom: 16,
}));

export const DownloadReportButton = styled(ButtonBase)(() => ({
  border: '1px solid #E89710',
  borderRadius: 8,
  color: '#E89710',
  fontWeight: 600,
  fontFamily: 'Montserrat',
  textTransform: 'uppercase',
  width: 300,
  height: 40,
  '&:hover': {
    backgroundColor: '#E89710',
    color: '#FAFCFF',
    border: '1px solid #E89710',
  },
}));

export const CertificateButton = styled(ButtonBase)(() => ({
  backgroundColor: '#378EF2',
  borderRadius: 8,
  width: 260,
  height: 40,
  color: '#FAFCFF',
  fontSize: 14,
  fontWeight: 600,
  fontFamily: 'Montserrat',
  textTransform: 'unset',
  '&:hover': {
    opacity: 0.8,
  },
  '&:disabled': {
    opacity: 0.4,
  },
}));

export const MiddleBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: 600,
  gap: 32,
  margin: '32px 0',
}));

export const PictureCardMedia = styled(CardMedia)(() => ({
  width: '80vw',
  height: 590,
  textAlign: 'center',
  borderRadius: '10px',
  margin: '32px auto',
  '&:hover': {
    opacity: 0.8,
    cursor: 'pointer',
  },
}));

export const ContainedButton = styled(ButtonBase)(() => ({
  height: 48,
  background: '#E89710',
  color: '#FAFCFF',
  font: '600 16px Montserrat, sans-serif',
  borderRadius: 8,
  padding: 16,
}));
