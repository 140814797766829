/* eslint-disable camelcase */
import { BaseUrl, myHeadersWithTokenContentType } from '../global';
import {
  ChangeUserProps,
  DetailResponse,
  GetAllUsersProps,
  GetUserProps,
  NewUserProps,
} from './types';

export const getAllUsers = (
  page: number,
  rows: number,
  searchString: string
): Promise<GetAllUsersProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(
    `${BaseUrl}/api/V1/users/search?search_string=${searchString}&page=${page}&size=${rows}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const getUser = (id: number): Promise<GetUserProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/users/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const addNewUser = (
  name: string,
  company_id: number,
  email: string,
  phone: string,
  // cpf: string,
  role: number
): Promise<NewUserProps> => {
  const jsonBody = JSON.stringify({
    name,
    company_id,
    email,
    phone,
    // cpf,
    role,
  });
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithTokenContentType(),
    body: jsonBody,
  };
  return fetch(`${BaseUrl}/api/V1/users`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const changeUser = (
  id: number,
  name: string,
  company_id: number,
  email: string,
  phone: string,
  // cpf: string,
  role: number
): Promise<ChangeUserProps> => {
  const jsonBody = JSON.stringify({
    name,
    company_id,
    email,
    phone,
    // cpf,
    role,
  });
  const requestOptions = {
    method: 'PUT',
    headers: myHeadersWithTokenContentType(),
    body: jsonBody,
  };
  return fetch(`${BaseUrl}/api/V1/users/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const deleteUser = (id: number): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };
  return fetch(`${BaseUrl}/api/V1/users/${id}`, requestOptions)
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
