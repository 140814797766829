/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable max-len */
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
} from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import {
  changeInspection,
  downloadInspectionXlsx,
  getInspection,
  uploadInspectionXLSX,
} from '../../../api/inspections';
import { newInspection } from '../../../api/reports';
import {
  IconCloseMS,
  IconDownloadMS,
  IconPublishMS,
} from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import {
  formatConclusion,
  getErrorMessage,
  reverseConclusion,
} from '../../../helpers';
import { useStoragedJwt } from '../../../hooks/useDecodedJwt';
import {
  CancelButton,
  CloseButton,
  ContainerButtons,
  DialogContainer,
  FormGROUP,
  InputBox,
  InputLabel,
  OutlinedButton,
  SaveButton,
  SelectInput,
  StyledDialog,
  TitleBox,
  TitleRef,
  UploadButton,
} from './styles';

interface NewReportModalProps {
  // eslint-disable-next-line react/require-default-props
  reportId?: number;
  open: boolean;
  closeModal: () => void;
}

export function NewReportModal({
  reportId,
  open,
  closeModal,
}: NewReportModalProps): JSX.Element {
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [responsible, setResponsible] = useState('');
  const [phone, setPhone] = useState('');
  const [engineer, setEngineer] = useState('');
  const [status, setStatus] = useState(0);
  const [finishesAt, setFinishesAt] = useState('');
  const [refNumber, setRefNumber] = useState(0);
  const [inspectionPerformed, setInspectionPerformed] =
    useState<boolean>(false);
  const [inspectionXLSX, setInspectionXLSX] = useState<FormData | undefined>();
  const [loading, setLoading] = useState(false);

  const decoded = useStoragedJwt();

  const { id } = useParams();
  const constructionId: number | undefined = Number(id);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const valueStatus = [
    { text: 'Carta enviada', value: 1 },
    { text: 'Vistoria agendada', value: 2 },
    { text: 'Remarcada pelo ocupante', value: 3 },
    { text: 'Remarcada pela Real Price', value: 4 },
    { text: 'Produzindo relatório', value: 5 },
    { text: 'Concluída', value: 6 },
    { text: 'Contato sem sucesso', value: 7 },
    { text: 'Não aprovada pelo cliente', value: 8 },
    { text: 'Cancelada pelo cliente', value: 9 },
    { text: 'Rejeitada pelo ocupante', value: 10 },
    { text: 'Notificação via cartório', value: 11 },
    { text: 'Notificação via telegrama', value: 12 },
  ];

  const clearFields = (): void => {
    setAddress('');
    setNumber('');
    setComplement('');
    setResponsible('');
    setPhone('');
    setEngineer('');
    setStatus(0);
    setFinishesAt('');
    setInspectionPerformed(false);
  };

  const handleCloseModal = useCallback((): void => {
    clearFields();
    closeModal();
  }, [closeModal]);

  function formatDate(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    let v = e.target.value.replace(/\D/g, '');
    v = v.replace(/(\d{2})(\d)/, '$1/$2');
    v = v.replace(/(\d{2})(\d)/, '$1/$2');
    e.target.value = v;
    setFinishesAt(v);
  }

  const getDataReport = useCallback(async (): Promise<void> => {
    setLoading(true);
    if (reportId !== undefined) {
      try {
        const { detail, data } = await getInspection(reportId);

        if (detail.description) {
          throw new Error(detail.description);
        }
        if (detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente');
        }
        if (data) {
          setAddress(data.address);
          setNumber(data.number);
          setComplement(data.complement);
          setRefNumber(data.reference_number);
          setResponsible(data.accountable_person);
          setPhone(data.phone);
          setEngineer(data.engineer);
          setStatus(data.status);
          if (data.finishes_at) {
            setFinishesAt(formatConclusion(data?.finishes_at));
          }

          if (
            data?.inspection_performed === null ||
            data?.inspection_performed === false
          ) {
            setInspectionPerformed(false);
          } else {
            setInspectionPerformed(data?.inspection_performed);
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }
    setLoading(false);
  }, [reportId, setErrorMessage, setOpenSnackbar, setSnackbarMessage]);

  const downloadModelXlsx = useCallback(async (): Promise<void> => {
    if (constructionId) {
      try {
        await downloadInspectionXlsx(constructionId);
        setTimeout(() => {
          handleCloseModal();
        }, 1500);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
        handleCloseModal();
      }
    }
  }, [
    constructionId,
    handleCloseModal,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
  ]);

  const uploadXLSX = useCallback(async (): Promise<void> => {
    if (inspectionXLSX !== undefined) {
      try {
        const response = await uploadInspectionXLSX(inspectionXLSX);

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente!');
        }

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }
        setInspectionXLSX(undefined);
        setSnackbarMessage('Sucesso!');
        setOpenSnackbar(true);
        setErrorMessage(false);
        handleCloseModal();
      } catch (error) {
        setInspectionXLSX(undefined);
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }
  }, [
    handleCloseModal,
    inspectionXLSX,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
  ]);

  const addNewReport = useCallback(async (): Promise<void> => {
    const obs = '';
    const convertFinishesAt =
      finishesAt !== '' ? reverseConclusion(finishesAt) : null;
    if (status !== 0) {
      try {
        const response = await newInspection(
          address,
          number,
          complement,
          responsible,
          phone,
          engineer,
          status,
          obs,
          convertFinishesAt,
          constructionId
        );
        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente');
        }

        setSnackbarMessage('Vistoria criada com sucesso');
        setOpenSnackbar(true);
        setErrorMessage(false);

        setTimeout(() => {
          closeModal();
        }, 1500);
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    } else {
      setSnackbarMessage('Selecione o status!');
      setOpenSnackbar(true);
      setErrorMessage(true);

      setTimeout(() => {
        setSnackbarMessage('');
        setOpenSnackbar(false);
        setErrorMessage(false);
      }, 2000);
    }
  }, [
    address,
    closeModal,
    complement,
    constructionId,
    engineer,
    finishesAt,
    number,
    phone,
    responsible,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    status,
  ]);

  const handleChangeInspection = useCallback(async (): Promise<void> => {
    const convertFinishesAt =
      finishesAt !== '' ? reverseConclusion(finishesAt) : null;
    if (reportId) {
      try {
        const { detail } = await changeInspection(
          reportId,
          address,
          number,
          complement,
          responsible,
          phone,
          engineer,
          status,
          convertFinishesAt,
          inspectionPerformed
        );
        if (detail.description) {
          throw new Error(detail.description);
        }

        if (detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente');
        }
        setSnackbarMessage('Inspeção editada com sucesso');
        setOpenSnackbar(true);
        setErrorMessage(false);
        handleCloseModal();
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }
  }, [
    address,
    complement,
    engineer,
    finishesAt,
    handleCloseModal,
    inspectionPerformed,
    number,
    phone,
    reportId,
    responsible,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    status,
  ]);

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0)) {
      setSnackbarMessage('Algo deu errado, tente novamente');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }
    const file = e.target.files[0];

    const fd = new FormData();
    fd.append('inspections', file);
    fd.append('construction_id', String(constructionId));
    setInspectionXLSX(fd);
  };

  useEffect(() => {
    if (inspectionXLSX !== undefined) {
      uploadXLSX();
    }
  }, [inspectionXLSX, uploadXLSX]);

  useEffect(() => {
    if (reportId) {
      getDataReport();
    }
  }, [getDataReport, reportId]);

  return (
    <StyledDialog open={open} onClose={handleCloseModal}>
      {loading ? (
        <CircularProgress size={128} />
      ) : (
        <DialogContainer>
          <CloseButton onClick={handleCloseModal}>{IconCloseMS}</CloseButton>

          {/* eslint-disable-next-line no-nested-ternary */}
          {refNumber !== 0 ? (
            refNumber < 10 ? (
              <TitleRef>Ref. 0{refNumber}</TitleRef>
            ) : (
              <TitleRef>Ref. {refNumber}</TitleRef>
            )
          ) : (
            <TitleBox>Nova vistoria</TitleBox>
          )}
          <Grid container spacing={6}>
            {decoded?.user.role !== 2 && (
              <>
                <Grid item xs={6}>
                  <UploadButton>
                    <label htmlFor="upload">
                      {IconPublishMS}&nbsp; Upload do modelo preenchido
                      <input
                        style={{ display: 'none' }}
                        type="file"
                        id="upload"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                          handleFileUpload(e)
                        }
                      />
                    </label>
                  </UploadButton>
                </Grid>
                <Grid item xs={6}>
                  <OutlinedButton onClick={downloadModelXlsx}>
                    {IconDownloadMS}&nbsp; Download do modelo
                  </OutlinedButton>
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <InputLabel variant="subtitle2">Endereço</InputLabel>
              <InputBox
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Preencha aqui o endereço"
              />
            </Grid>
            <Grid item xs={3}>
              <InputLabel variant="subtitle2">Número</InputLabel>
              <InputBox
                value={number}
                placeholder="n˚"
                onChange={(e) => setNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <InputLabel variant="subtitle2">Complemento</InputLabel>
              <InputBox
                value={complement}
                placeholder=""
                onChange={(e) => setComplement(e.target.value)}
              />
            </Grid>
            <Grid item xs={8}>
              <InputLabel variant="subtitle2">
                Responsável pelo imóvel
              </InputLabel>
              <InputBox
                value={responsible}
                placeholder="Preencha aqui o nome do responsável"
                onChange={(e) => setResponsible(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel variant="subtitle2">Contato</InputLabel>
              <InputBox
                value={phone}
                placeholder="N˚ com código de área"
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid item xs={8}>
              <InputLabel variant="subtitle2">
                Engenheiro responsável
              </InputLabel>
              <InputBox
                value={engineer}
                placeholder="Preencha aqui o nome do Engenheiro"
                onChange={(e) => setEngineer(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel variant="subtitle2">Status</InputLabel>
              <FormControl sx={{ width: '100%' }}>
                <SelectInput
                  value={status}
                  onChange={(e) => setStatus(e.target.value as number)}
                >
                  <MenuItem value={0} disabled>
                    Selecione o status
                  </MenuItem>
                  {valueStatus.map((st) => (
                    <MenuItem key={st.value} value={st.value}>
                      {st.text}
                    </MenuItem>
                  ))}
                </SelectInput>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <InputLabel variant="subtitle2">Previsão de conclusão</InputLabel>
              <InputBox
                value={finishesAt}
                placeholder="dd/mm/aaaa"
                onChange={(e) => formatDate(e)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormGROUP className="formGroup">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={inspectionPerformed}
                      onChange={(e) => setInspectionPerformed(e.target.checked)}
                    />
                  }
                  label="Executada"
                />
              </FormGROUP>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ContainerButtons>
              <CancelButton onClick={handleCloseModal} type="button">
                Cancelar
              </CancelButton>
              {reportId ? (
                <SaveButton onClick={handleChangeInspection} type="button">
                  Salvar
                </SaveButton>
              ) : (
                <SaveButton onClick={addNewReport} type="button">
                  Salvar
                </SaveButton>
              )}
            </ContainerButtons>
          </Grid>
        </DialogContainer>
      )}
    </StyledDialog>
  );
}
