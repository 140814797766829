import InputUnstyled from '@mui/base/InputUnstyled';
import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    minWidth: 1200,
    borderRadius: 16,
    backgroundColor: '#313234',
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minHeight: 600,
  padding: 40,
}));

export const CloseButton = styled(ButtonBase)(() => ({
  color: '#707070',
  float: 'right',
  '&:hover': {
    color: '#edeff2',
  },
  '& span': {
    fontSize: 28,
    fontWeight: 600,
  },
}));

export const OutlinedButton = styled(Button)(() => ({
  border: '1px solid #E89710',
  textTransform: 'lowercase',
  borderRadius: 4,
  width: 200,
}));

export const FilledButton = styled(Button)(() => ({
  backgroundColor: '#E89710',
  color: '#EDEFF2',
  textTransform: 'lowercase',
  borderRadius: 4,
  width: 200,
}));

export const TitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  marginTop: '16px',
  color: '#F2F2F2',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: 18,
}));

export const InputBox = styled(OutlinedInput)(() => ({
  backgroundColor: '#375285',
  color: '#fff',
  borderRadius: 4,
  outline: 'none',

  '&::placeholder': {
    color: '#ccc',
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E89710cc',
  },

  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E89710',
  },
}));

export const InputLabel = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 'bold',
  color: '#f2f2f2',
  marginLeft: 4,
  marginBottom: 8,
}));

export const ContainerButtons = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 24,
  marginTop: 64,
  marginRight: 24,
}));

export const TextInputUnstyled = styled(InputUnstyled)(() => ({
  width: 500,
  fontFamily: 'Montserrat, sans-serif',
  fontSize: ' 0.875rem',
  fontWeight: 400,
  lineHeight: 1.5,
  padding: 12,
  borderRadius: 12,
  outline: 'none',
  '&:focus': {
    borderColor: '#E89710',
    boxShadow: '0 0 0 3px #e89710cc',
  },
  '&:hover': {
    borderColor: '#E89710',
    cursor: 'pointer',
  },
  '&:focus-visible': {
    outline: 0,
    color: 'red',
  },
}));
