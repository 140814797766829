export const Constants = {
  user: 'Usuário',
  panel: 'Painel de usuários',
  ref: 'Ref',
  companies: 'Empresas',
  name: 'Nome',
  cpf: 'CPF',
  email: 'E-mail',
  actions: 'Ações',
};
