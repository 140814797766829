export const Constants = {
  settings: 'Configurações',
  user: 'Usuário:',
  company: 'Empresa:',
  logo: 'Logo:',
  deleteUser: 'Excluir usuário',
  uploadLogo: 'upload logo',
  uploadText:
    'Observe a proporção da imagem, a logo será adicionada conforme visualização acima.',
  selectLogo: 'selecionar nova logo',
  save: 'salvar',
  changePassword: 'Alterar senha',
  currentPassword: 'senha atual',
  newPassword: 'nova senha',
  confirmPassword: 'confirmar nova senha',
  cancel: 'cancelar',
};
