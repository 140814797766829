import {
  Box,
  ButtonBase,
  Dialog,
  FormGroup,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 16,
    backgroundColor: '#313234',
    minWidth: 920,
  },
}));

export const DialogContainer = styled(Box)(() => ({
  minHeight: 600,
  padding: 40,
}));

export const CloseButton = styled(ButtonBase)(() => ({
  color: '#707070',
  float: 'right',
  '&:hover': {
    color: '#EDEFF2',
  },
  '& span': {
    fontSize: 28,
    fontWeight: 600,
  },
}));

export const TitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 16,
  color: '#F2F2F2',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: 18,
}));

export const TitleRef = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '16px 0',
  color: '#E89710',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: 18,
}));

export const InputLabel = styled(Typography)(() => ({
  fontSize: 12,
  fontWeight: 600,
  color: '#F2F2F2',
  marginLeft: 4,
  marginBottom: 8,
}));

export const UploadButton = styled('div')(() => ({
  border: '1px solid #378EF2',
  color: '#378EF2',
  outline: 'none',
  borderRadius: 6,
  width: 400,
  height: 48,
  fontWeight: 600,
  fontFamily: 'Montserrat',

  cursor: 'pointer',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& label': {
    fontSize: 13.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: '100%',
    cursor: 'pointer',
  },
}));

export const OutlinedButton = styled(ButtonBase)(() => ({
  border: '1px solid #378EF2',
  color: '#378EF2',
  outline: 'none',
  borderRadius: 6,
  width: 400,
  height: 48,
  fontWeight: 600,
  fontFamily: 'Montserrat',
}));

export const InputBox = styled(OutlinedInput)(() => ({
  backgroundColor: '#375285',
  color: '#FFFFFF',
  borderRadius: 4,
  outline: 'none',
  width: '100%',

  '&::placeholder': {
    color: '#CCCCCC',
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E89710cc',
  },

  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E89710',
  },
}));

export const ContainerButtons = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  gap: 32,
  marginTop: 48,
  paddingBottom: 16,
}));

export const CancelButton = styled(ButtonBase)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  color: theme.palette.secondary.main,
  width: 200,
  height: 48,
  borderRadius: 6,
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.fontSize,
}));

export const SaveButton = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: '#FFFFFF',
  width: 200,
  height: 48,
  borderRadius: 6,
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.fontSize,
}));

export const SelectInput = styled(Select)(() => ({
  backgroundColor: '#375285',
  color: '#fff',
  borderRadius: 4,
  outline: 'none',

  '&::placeholder': {
    color: '#ccc',
  },

  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E89710cc',
  },

  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E89710',
  },
}));

export const FormGROUP = styled(FormGroup)(() => ({
  marginTop: 32,
  marginLeft: 64,
  color: '#fff',

  '& .MuiFormControlLabel-label': {
    color: '#FFF',
    fontWeight: '#FFF',
  },

  '& .MuiCheckbox-root': {
    color: '#375285',
  },
}));
