import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledGrid = styled(Grid)(() => ({
  padding: '40px 120px 60px 228px ',
  width: 'inherit',
  display: 'flex',
  flexDirection: 'column',
}));

export const TitleTypography = styled(Typography)(() => ({
  color: '#EDEFF2',
  fontSize: '21px',
  fontWeight: '600',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '20px',
  '& span': {
    color: '#E89710',
    fontSize: '24px',
    paddingBottom: '3px',
  },
}));

export const ProfileBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  margin: '20px 0',
}));

export const SubtitleTypography = styled(Typography)(() => ({
  color: '#EDEFF2',
  fontSize: '18px',
  fontWeight: '600',
}));

export const DataTypography = styled(Typography)(() => ({
  color: '#378EF2',
  fontSize: '18px',
  fontWeight: '400',
}));

export const LogoContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  marginTop: '20px',
  minWidth: '400px',
}));

export const LogoBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '30px',
  margin: '0 20px 10px',
  padding: '40px',
  border: '1px solid #2B3550',
  borderRadius: '10px',
  backgroundColor: '#0A1123',
  width: '100%',
}));

export const UploadBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '600px',
}));

export const DeleteBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  margin: '20px',
}));
