export const Constants = {
  reports: 'Relatórios',
  deadline: 'previsão geral: ',
  lastUpdate: 'última atualização:',
  inspection: 'Painel de vistorias',
  finalReport: 'relatório de conclusão',
  ref: 'Ref',
  address: 'Endereço',
  accountable: 'Responsável pelo imóvel',
  contact: 'Contato',
  engineer: 'Engenheiro Responsável',
  status: 'Status',
  conclusion: 'Previsão de conclusão',
  obs: 'Obs.',
  noObs: 'Sem obs.',
  download: 'Download',
  noDownload: 'sem arquivos',
  chartTitle: 'Evolução das vistorias',
  totalReports: 'total de relatórios',
  dashboard: 'Dashboard',
  refDialog: 'Ref. ',
  observations: 'Observações',
  certificate: 'Exibir certificado blockchain',
  downloadButton: 'Download do relatório completo',
  addReport: 'Adicionar vistoria',
  art: 'Exibir ART',
};
