// import { Container } from './styles';

import Menu from '../../components/Menu';
import { IconPersonMS } from '../../constants/icons';
import { Constants } from '../../constants/users';
import { StyledGrid, TitleTypography } from './styles';
import { UserTable } from './Table';

export function Users(): JSX.Element {
  return (
    <>
      <Menu />
      <StyledGrid>
        <TitleTypography>
          {IconPersonMS}
          {Constants.user}
        </TitleTypography>

        <UserTable />
      </StyledGrid>
    </>
  );
}
