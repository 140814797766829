import { FormControl, Grid, MenuItem } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { CompanyProps } from '../../../api/companies/types';
import {
  addNewUser,
  changeUser,
  deleteUser,
  getUser,
} from '../../../api/users';
import { IconCloseMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import { getErrorMessage } from '../../../helpers';
import { useStoragedJwt } from '../../../hooks/useDecodedJwt';
import ConfirmationDialog from '../../ConfirmationDialog';
import { NewCompanyModal } from '../NewCompany';
import {
  StyledDialog,
  DialogContainer,
  TitleBox,
  InputLabel,
  InputBox,
  CloseButton,
  ContainerButtons,
  OutlinedButton,
  FilledButton,
  SelectInput,
} from './styles';

interface NewUserProps {
  open: boolean;
  closeModal: () => void;
  companies: CompanyProps[];
  // eslint-disable-next-line react/require-default-props
  id?: number | undefined;
}

export function NewUser({
  open,
  closeModal,
  companies,
  id,
}: NewUserProps): JSX.Element {
  const [companyId, setCompanyId] = useState(0);
  const [userName, setUserName] = useState('');
  // const [cpf, setCPF] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState(0);
  const decoded = useStoragedJwt();

  const [openNewCompanyModal, setOpenNewCompanyModal] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const clearFields = (): void => {
    setCompanyId(0);
    setUserName('');
    // setCPF('');
    setEmail('');
    setPhone('');
    setRole(0);
  };

  const handleCloseNewCompanyModal = (): void => {
    clearFields();
    setOpenNewCompanyModal(!openNewCompanyModal);
  };

  const handleCloseNewUserModal = (): void => {
    clearFields();
    closeModal();
  };

  const getDataCallback = useCallback(async () => {
    try {
      if (id) {
        const response = await getUser(id);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente');
        }

        setUserName(response.data?.name || '');
        setCompanyId(response.data?.company.id || 0);
        setEmail(response.data?.email || '');
        setPhone(response.data?.phone || '');
        setRole(response.data?.role || 0);
        // setCPF(response.data?.cpf || '');
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, [id, setErrorMessage, setOpenSnackbar, setSnackbarMessage]);

  const createNewUser = useCallback(async (): Promise<void> => {
    if (companyId) {
      try {
        const response = await addNewUser(
          userName,
          companyId,
          email,
          phone,
          // cpf,
          role
        );
        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente');
        }

        setSnackbarMessage('Usuário criado com sucesso!');
        setOpenSnackbar(true);
        setErrorMessage(false);

        clearFields();
        closeModal();
      } catch (error) {
        setSnackbarMessage(getErrorMessage(error));
        setOpenSnackbar(true);
        setErrorMessage(true);
      }
    }
  }, [
    closeModal,
    companyId,
    // cpf,
    email,
    phone,
    role,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    userName,
  ]);

  const handleChangeUser = useCallback(async (): Promise<void> => {
    try {
      if (id) {
        const response = await changeUser(
          id,
          userName,
          companyId,
          email,
          phone,
          // cpf,
          role
        );

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente');
        }

        setSnackbarMessage('Usuário editado com sucesso!');
        setOpenSnackbar(true);
        setErrorMessage(false);

        setTimeout(() => {
          clearFields();
          closeModal();
        }, 1500);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, [
    closeModal,
    companyId,
    // cpf,
    email,
    id,
    phone,
    role,
    setErrorMessage,
    setOpenSnackbar,
    setSnackbarMessage,
    userName,
  ]);

  const handleDeleteUser = async (): Promise<void> => {
    try {
      if (id) {
        const response = await deleteUser(id);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== 0) {
          throw new Error('Algo deu errado, tente novamente');
        }

        setSnackbarMessage('Usuário excluído com sucesso');
        setOpenSnackbar(true);
        setErrorMessage(false);

        setTimeout(() => {
          getDataCallback();
          clearFields();
          closeModal();
        }, 2000);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  return (
    <>
      <StyledDialog open={open} onClose={closeModal}>
        <DialogContainer>
          <CloseButton onClick={handleCloseNewUserModal}>
            {IconCloseMS}
          </CloseButton>
          {id ? (
            <TitleBox>Editar usuário</TitleBox>
          ) : (
            <TitleBox>Novo usuário</TitleBox>
          )}

          <Grid container spacing={6}>
            <Grid item xs={6}>
              <InputLabel variant="subtitle2">Empresa</InputLabel>
              <FormControl sx={{ width: 500 }}>
                <SelectInput
                  value={companyId}
                  onChange={(event) =>
                    setCompanyId(event.target.value as number)
                  }
                  sx={{ color: 'white' }}
                  disabled={!!id}
                >
                  {companies.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </SelectInput>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <OutlinedButton
                sx={{
                  marginTop: 3.9,
                  width: 350,
                  paddingLeft: 4,
                  paddingRight: 4,
                }}
                onClick={handleCloseNewCompanyModal}
                disabled={!!id}
              >
                + Adicionar empresa
              </OutlinedButton>
            </Grid>
            <Grid item xs={6}>
              <InputLabel variant="subtitle2">Nome</InputLabel>
              <InputBox
                sx={{ width: 500 }}
                placeholder="Preencha aqui o nome do usuário"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel variant="subtitle2">E-mail</InputLabel>
              <InputBox
                sx={{ width: 500 }}
                placeholder="Preencha aqui o e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel variant="subtitle2">Contato</InputLabel>
              <InputBox
                placeholder="Preencha aqui o nº do telefone com o código de área"
                sx={{ width: 500 }}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel variant="subtitle2">Função</InputLabel>
              <FormControl sx={{ width: 500 }}>
                <SelectInput
                  value={role}
                  onChange={(e) => setRole(e.target.value as number)}
                  sx={{ color: 'white' }}
                >
                  <MenuItem value={3} disabled={decoded?.user.role !== 3}>
                    Admin
                  </MenuItem>
                  <MenuItem value={2}>Cliente</MenuItem>
                  <MenuItem value={1}>Operador</MenuItem>
                </SelectInput>
              </FormControl>
            </Grid>
          </Grid>
          <ContainerButtons>
            {id && (
              <ConfirmationDialog
                title="excluir usuário"
                text={`o usuário ${userName}`}
                modalCallback={handleDeleteUser}
              />
            )}
            <OutlinedButton onClick={handleCloseNewUserModal}>
              Cancelar
            </OutlinedButton>
            {id ? (
              <FilledButton onClick={handleChangeUser}>Salvar</FilledButton>
            ) : (
              <FilledButton onClick={createNewUser}>Salvar</FilledButton>
            )}
          </ContainerButtons>
        </DialogContainer>
      </StyledDialog>
      <NewCompanyModal
        open={openNewCompanyModal}
        closeModal={handleCloseNewCompanyModal}
      />
    </>
  );
}
