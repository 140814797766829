/* eslint-disable react-hooks/exhaustive-deps */
import { CardMedia } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getCompany } from '../../api/companies';
import { CompanyData } from '../../api/companies/types';
import { deleteUser, getUser } from '../../api/users';
import { UserData } from '../../api/users/types';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import Menu from '../../components/Menu';
import Snackbar from '../../components/Snackbar';
import { IconSettingsMS } from '../../constants/icons';
import { Constants } from '../../constants/settings';
import { GlobalContext } from '../../context/global';
import { getErrorMessage } from '../../helpers';
import { useStoragedJwt } from '../../hooks/useDecodedJwt';
import LogoDialog from './LogoDialog';
import ResetPassword from './ResetPasswordDialog';
import {
  DataTypography,
  DeleteBox,
  LogoBox,
  LogoContainer,
  ProfileBox,
  StyledGrid,
  SubtitleTypography,
  TitleTypography,
  UploadBox,
} from './styles';

export function Settings(): JSX.Element {
  const [userData, setUserData] = useState<UserData>();
  const [companyData, setCompanyData] = useState<CompanyData>();
  const [logoImg, setLogoImg] = useState('');
  const { openSnackbar, setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const navigate = useNavigate();

  const decoded = useStoragedJwt();
  const companyId = decoded?.user.company_id as number;
  const userId = decoded?.user.id as number;

  const getDataCallback = useCallback(async () => {
    try {
      const response = await getUser(userId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      setUserData(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }

    try {
      const response = await getCompany(companyId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      setCompanyData(response.data);

      if (response.data) {
        setLogoImg(response.data.logo);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const handleDelete = async (): Promise<void> => {
    try {
      const response = await deleteUser(userId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSnackbarMessage('Usuário excluído');
      setOpenSnackbar(true);
      setErrorMessage(false);
      setTimeout(() => {
        localStorage.removeItem('precautionary-token');
        navigate('/login');
      }, 2000);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setOpenSnackbar(true);
      setErrorMessage(true);
    }
  };

  return (
    <>
      <Menu />
      <StyledGrid container>
        <TitleTypography>
          {IconSettingsMS}
          {Constants.settings}
        </TitleTypography>
        <ProfileBox>
          <SubtitleTypography>{Constants.user}</SubtitleTypography>
          <DataTypography>{userData?.email}</DataTypography>
          <ResetPassword />
        </ProfileBox>
        <ProfileBox>
          <SubtitleTypography>{Constants.company}</SubtitleTypography>
          <DataTypography>{companyData?.name}</DataTypography>
        </ProfileBox>
        <UploadBox>
          <LogoContainer>
            <SubtitleTypography>{Constants.logo}</SubtitleTypography>
            <LogoBox>
              {logoImg && (
                <CardMedia
                  image={logoImg}
                  title="logo"
                  component="img"
                  height="60"
                  sx={{ objectFit: 'contain' }}
                />
              )}
              <LogoDialog
                id={companyId}
                lastLogo={logoImg}
                modalCallback={getDataCallback}
              />
            </LogoBox>
          </LogoContainer>
        </UploadBox>
        <DeleteBox>
          <ConfirmationDialog
            title={Constants.deleteUser}
            text={userData?.email ? userData?.email : 'usuário'}
            modalCallback={handleDelete}
          />
        </DeleteBox>
      </StyledGrid>
      {openSnackbar && <Snackbar />}
    </>
  );
}
