/* eslint-disable react-hooks/exhaustive-deps */
import { CardMedia } from '@mui/material';
import { useCallback, useContext, useEffect } from 'react';

import { getCompany } from '../../api/companies';
import { GlobalContext } from '../../context/global';
import { useStoragedJwt } from '../../hooks/useDecodedJwt';
import { StyledAppbar } from './styles';

interface NavbarProps {
  open: boolean;
}

export function Navbar({ open }: NavbarProps): JSX.Element {
  const { navbarLogo, setNavbarLogo } = useContext(GlobalContext);
  const decoded = useStoragedJwt();
  const companyId = decoded?.user.company_id as number;

  const getDataCallback = useCallback(async () => {
    if (navbarLogo) {
      return;
    }

    try {
      const response = await getCompany(companyId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== 0) {
        throw new Error('Algo deu errado, tente novamente');
      }

      if (response.data) {
        setNavbarLogo(response.data.logo);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  return (
    <StyledAppbar position="fixed" open={open}>
      {navbarLogo && (
        <CardMedia
          image={navbarLogo}
          title="logo"
          component="img"
          height="60"
          sx={{ objectFit: 'contain', width: '50%' }}
        />
      )}
    </StyledAppbar>
  );
}
