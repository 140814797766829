/* eslint-disable react/jsx-no-duplicate-props */
import { TextField } from '@mui/material';

import { validatePassword } from '../../helpers';
import { IconVisibility } from '../InputIcon';

interface ResetPasswordTextFieldProps {
  id: string;
  value: string;
  setValue: (value: string) => void;
  textType: boolean;
  setTextType: (type: boolean) => void;
}

export function ResetPasswordTextField({
  id,
  value,
  setValue,
  textType,
  setTextType,
}: ResetPasswordTextFieldProps): JSX.Element {
  return (
    <TextField
      required
      id={id}
      variant="filled"
      type={textType ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <IconVisibility
            showPassword={textType}
            setShowPassword={setTextType}
          />
        ),
      }}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      sx={{
        marginBottom: '32px',
        backgroundColor: '#378EF280',
        '& .MuiInputBase-input': {
          padding: '12px',
          color: '#FFF',
          fontSize: '20px',
        },
      }}
    />
  );
}

interface NewPasswordTextFieldProps {
  id: string;
  value: string;
  setValue: (value: string) => void;
  textType: boolean;
  setTextType: (type: boolean) => void;
  valueError: boolean;
  setValueError: (valueError: boolean) => void;
}

export function NewPasswordTextField({
  id,
  value,
  setValue,
  textType,
  setTextType,
  valueError,
  setValueError,
}: NewPasswordTextFieldProps): JSX.Element {
  return (
    <TextField
      required
      id={id}
      variant="filled"
      type={textType ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <IconVisibility
            showPassword={textType}
            setShowPassword={setTextType}
          />
        ),
      }}
      value={value}
      error={valueError}
      helperText={
        valueError
          ? 'Mínimo de 8 caracteres com 1 letra minúscula, 1 maíuscula e 1 número'
          : ' '
      }
      inputProps={{ maxLength: 18 }}
      onChange={(e) => {
        setValue(e.target.value);
        !validatePassword(e.target.value)
          ? setValueError(true)
          : setValueError(false);
      }}
      sx={{
        backgroundColor: '#378EF280',
        '& .MuiInputBase-input': {
          padding: '12px',
          color: '#FFF',
          fontSize: '20px',
        },
        '& .MuiFormHelperText-root': {
          backgroundColor: '#313234',
          margin: '-2px -2px',
          padding: '8px 10px',
        },
        '& .MuiInputBase-root:before': {
          borderBottom: 'none',
        },
      }}
    />
  );
}
